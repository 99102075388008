import WhyUSImg1 from "../../Assets/HomePage/WhyUSImg1.png";
import WhyUSImg2 from "../../Assets/HomePage/WhyUSImg2.png";
import WhyUSImg3 from "../../Assets/HomePage/WhyUSImg3.png";
import WhyUSImg4 from "../../Assets/HomePage/WhyUSImg4.png";
import WhyUSImg5 from "../../Assets/HomePage/WhyUSImg5.png";

function WhyUsSection({ content }) {
  return (
    <div className=" w-full px-10 py-5 gap-10 rounded-lg bg-orange-100 flex flex-col justify-center items-center">
      <h4 className=" font-bold ">{content?.heading}</h4>
      <div className=" flex flex-wrap justify-center items-center gap-10 w-full">
        <h4 className=" font-bold text-2xl text-red-500">
          &#x25cf; {content?.finance}
        </h4>
        <h4 className=" font-bold text-2xl text-red-500">
          &#x25cf; {content?.savings}
        </h4>
        <h4 className=" font-bold text-2xl text-red-500">
          &#x25cf; {content?.variety}
        </h4>
      </div>
    </div>
  );
}

export default WhyUsSection;
