import { useParams } from "react-router-dom";
import useContentFetcher from "../CustomHooks/ContentFetcher/ContentFetcher";
import UniversalLoader from "../Components/UniversalLoader/UniversalLoader";
import ErrorPage from "./ErrorPage";
import PageHeader from "../Components/PageHeader/PageHeader";

import ShowroomImg1 from "../Assets/Showrooms/ShowroomImg1.png";
import ShowroomImg2 from "../Assets/Showrooms/ShowroomImg2.png";
import ShowroomImg3 from "../Assets/Showrooms/ShowroomImg3.png";
import ShowroomImg4 from "../Assets/Showrooms/ShowroomImg4.png";
import ShowroomImg5 from "../Assets/Showrooms/ShowroomImg5.png";
import ShowroomImg6 from "../Assets/Showrooms/ShowroomImg6.png";
import ShowroomImg7 from "../Assets/Showrooms/ShowroomImg7.png";
import ShowroomImg8 from "../Assets/Showrooms/ShowroomImg8.png";

import NewShowroomImg1 from "../Assets/Showrooms/NewShowroomImg1.jpg";
import NewShowroomImg2 from "../Assets/Showrooms/NewShowroomImg2.jpg";
import NewShowroomImg3 from "../Assets/Showrooms/NewShowroomImg3.jpg";
import NewShowroomImg4 from "../Assets/Showrooms/NewShowroomImg4.jpg";
import NewShowroomImg5 from "../Assets/Showrooms/NewShowroomImg5.jpg";

import ShowroomTitleBgImg from "../Assets/Showrooms/ShowroomTitleBgImg.png";

import ProductImg from "../Assets/Products/ProductImg1.png";
import ContactSection from "../Components/ContactSection/ContactSection";
import Catalogues from "../Components/Catalogues/Catalogues";
import DealerCard from "../Components/DealerCard/DealerCard";
import DealershipLocator from "../Components/DealershipLocator/DealershipLocator";
import { useState } from "react";
import TestRideFormModal from "../Components/TestRideFormModal/TestRideFormModal";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/autoplay";
import useIntersectionObserver from "../Animations/animationObserver";

function SingleSowroomPage() {
  const { id } = useParams();

  const leftRef1 = useIntersectionObserver("animate-slide-left");
  const rightRef1 = useIntersectionObserver("animate-slide-right");
  const leftRef2 = useIntersectionObserver("animate-slide-left");
  const rightRef2 = useIntersectionObserver("animate-slide-right");

  const { content, contentLoading, contentError } = useContentFetcher(
    "showrooms"
  );

  const [testRideMoalOpen, setTestRideModalOpen] = useState(false);

  const handleTestRideFormOpen = () => setTestRideModalOpen(true);
  const handleTestRideFormClose = () => setTestRideModalOpen(false);

  if (contentLoading) return <UniversalLoader />;

  if (contentError)
    return (
      <ErrorPage
        error={contentError?.message ? contentError?.message : contentError}
      />
    );

  const currentShowroom = content?.showroomsData?.find(
    (showroom) => showroom?.id === id
  );

  const showroomImages = [
    NewShowroomImg2,
    NewShowroomImg3,
    NewShowroomImg4,
    NewShowroomImg5,
  ];

  return (
    <div className=" w-full flex flex-col justify-center items-center gap-5 lg:gap-10 text-gray-600">
      <div
        className=" mt-[160px] w-[96%] md:w-[90%] bg-white rounded-lg flex flex-col gap-10 lg:gap-20 
      justify-center items-start p-5 md:p-10"
      >
        <PageHeader
          heading={content?.showroom}
          subHeading={currentShowroom?.title}
        />
        <div className=" w-full flex flex-col justify-center items-center relative lg:mb-10">
          {/* <div className=" w-full flex flex-wrap justify-center items-center lg:grid lg:grid-cols-2 gap-5 gap-y-5 lg:place-items-center">
            {showroomImages?.map((img, index) => (
              <img
                key={index}
                src={img}
                className=" w-[500px] h-[300px] rounded-lg border-b-2 border-red-500 object-cover"
              />
            ))}
          </div> */}
          <div className=" flex justify-center items-center w-full">
            <Swiper
              modules={[Autoplay]}
              spaceBetween={0}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              slidesPerView={1}
              // onSlideChange={() => console.log("slide change")}
              // onSwiper={(swiper) => console.log(swiper)}
            >
              {showroomImages?.map((img, index) => (
                <SwiperSlide>
                  <img
                    key={index}
                    src={img}
                    className=" w-full h-[300px] lg:h-[500px] rounded-lg border-b-2 border-red-500 object-cover"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <h2
            style={{ backgroundImage: `url(${ShowroomTitleBgImg})` }}
            className=" bg-white text-red-500 font-semibold text-3xl lg:text-5xl px-10 py-10 
        rounded-md shadow-lg bg-cover bg-no-repeat lg:absolute lg:z-10 lg:-bottom-20 "
          >
            {currentShowroom?.title}
          </h2>
        </div>

        <p className=" text-left font-semibold text-lg">
          {currentShowroom?.desc}
        </p>
        <div className=" w-full flex flex-col lg:flex-row gap-y-5 justify-between items-center">
          <img
            ref={leftRef1}
            className=" w-[400px] lg:w-[30%] rounded-lg"
            src={ProductImg}
            alt="Product"
          />
          <div
            ref={rightRef1}
            className=" lg:w-[45%] flex flex-col justify-start items-start text-left gap-10"
          >
            <div className=" w-full flex flex-col justify-start items-start text-left gap-5 ">
              <h5 className=" font-semibold text-2xl">
                {content?.ourLocation}
              </h5>
              <p>{currentShowroom?.address}</p>
              <a
                href={currentShowroom?.mapLink}
                target="_blank"
                className=" cursor-pointer"
              >
                <span
                  className=" text-xs font-semibold text-red-500 px-5 py-2 rounded-md border-2 border-red-500
               hover:bg-red-500 hover:text-white hover:border-transparent"
                >
                  {content?.googleMap}
                </span>
              </a>
            </div>
            <div className=" w-full flex flex-col justify-start items-start text-left gap-5 ">
              <h5 className=" font-semibold text-2xl">{content?.contact}</h5>
              <div className=" flex flex-col md:flex-row justify-start items-start md:items-center gap-10">
                <a
                  href={`tel:${currentShowroom?.phoneNumber}`}
                  className=" cursor-pointer"
                >
                  <span
                    className=" text-xs font-semibold text-red-500 px-5 py-2 rounded-md border-2 border-red-500
               hover:bg-red-500 hover:text-white hover:border-transparent"
                  >
                    {currentShowroom?.phoneNumber}
                  </span>
                </a>
                <a
                  href={`mailto:${currentShowroom?.email}`}
                  className=" cursor-pointer"
                >
                  <span
                    className=" text-xs font-semibold text-red-500 px-5 py-2 rounded-md border-2 border-red-500
               hover:bg-red-500 hover:text-white hover:border-transparent"
                  >
                    {currentShowroom?.email}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className=" w-full flex flex-col gap-y-5 justify-center items-center">
          <h2
            style={{ backgroundImage: `url(${ShowroomTitleBgImg})` }}
            className=" bg-white text-red-500 font-semibold text-xl lg:text-3xl px-5 py-5
        rounded-md shadow-lg self-center bg-cover bg-no-repeat"
          >
            {content?.otherShowrooms}
          </h2>
          <div className=" w-full flex flex-wrap justify-between items-center text-left gap-10">
            {content?.showroomsData2?.map((data, index) => (
              <div
                ref={index % 2 === 0 ? leftRef2 : rightRef2}
                key={index}
                className=" flex flex-col justify-center items-start gap-5"
              >
                <div className=" w-full flex flex-col justify-start items-start text-left gap-5 ">
                  <h5 className=" font-semibold text-2xl">{data?.location}</h5>
                  <p>{data?.address}</p>
                  <a
                    href={data?.mapLink}
                    target="_blank"
                    className=" cursor-pointer"
                  >
                    <span
                      className=" text-xs font-semibold text-red-500 px-5 py-2 rounded-md border-2 border-red-500
               hover:bg-red-500 hover:text-white hover:border-transparent"
                    >
                      {content?.googleMap}
                    </span>
                  </a>
                </div>
                <div className=" w-full flex flex-col justify-start items-start text-left gap-5 ">
                  <h5 className=" font-semibold text-2xl">
                    {content?.contact}
                  </h5>
                  <div className=" flex flex-col md:flex-row justify-start items-start md:items-center gap-10">
                    <a
                      href={`tel:${data?.phoneNumber}`}
                      className=" cursor-pointer"
                    >
                      <span
                        className=" text-xs font-semibold text-red-500 px-5 py-2 rounded-md border-2 border-red-500
               hover:bg-red-500 hover:text-white hover:border-transparent"
                      >
                        {data?.phoneNumber}
                      </span>
                    </a>
                    <a
                      href={`mailto:${data?.email}`}
                      className=" cursor-pointer"
                    >
                      <span
                        className=" text-xs font-semibold text-red-500 px-5 py-2 rounded-md border-2 border-red-500
               hover:bg-red-500 hover:text-white hover:border-transparent"
                      >
                        {data?.email}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <DealershipLocator handleTestRideFormOpen={handleTestRideFormOpen} />
        <Catalogues />
        <ContactSection />
      </div>
      <TestRideFormModal
        open={testRideMoalOpen}
        handleClose={handleTestRideFormClose}
      />
    </div>
  );
}

export default SingleSowroomPage;
