import useIntersectionObserver from "../../Animations/animationObserver";
import BecomeADealerImg from "../../Assets/HomePage/BecomeADealerImg.png";
import BecomeADealerRightImg from "../../Assets/HomePage/BecomeADealerRightImg.jpeg";

function BecomeDealer({ content, handleDealershipFormOpen }) {
  const leftRef = useIntersectionObserver("animate-slide-left");
  const rightRef = useIntersectionObserver("animate-slide-right");

  return (
    <div
      className=" flex flex-col lg:flex-row justify-between items-center w-[95%] lg:w-[90%] 
    h-auto lg:h-[420px] lg:py-5  gap-10 bg-white py-5 px-5 rounded-lg"
    >
      <div
        ref={leftRef}
        style={{
          backgroundImage: `url(${BecomeADealerImg})`,
          backgroundColor: "chocolate",
          backgroundBlendMode: "multiply",
        }}
        className=" border-2 border-black bg-cover bg-no-repeat flex flex-col justify-center items-center w-full lg:w-[45%] gap-5 
        text-white font-semibold p-10 rounded-lg lg:h-[98%]"
      >
        <p>{content?.text}</p>
        <button
          className=" px-5 py-1 bg-orange-400 rounded-lg"
          onClick={handleDealershipFormOpen}
        >
          {content?.becomeADealer}
        </button>
      </div>
      <div ref={rightRef} className=" w-full lg:w-[45%] lg:h-[98%]">
        <img
          src={BecomeADealerRightImg}
          alt="Become A Dealer"
          className=" w-[100%] lg:h-[100%] border-2 border-white rounded-lg"
        />
      </div>
    </div>
  );
}

export default BecomeDealer;
