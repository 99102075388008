const internalLinks = {
  home: "/",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  blogs: "/blogs",
  singleBlogPage: "/blogs",
  products: "/products",
  singleProductPage: "/products",
  showrooms: "/showrooms",
  faq: "/",
  customerService: "/",
  warranty: "/",
  returns: "",
  shipping: "",
  privacyPolicy: "",
};

export default internalLinks;
