function ErrorPage({ error }) {
  // console.log(error);
  return (
    <div className=" w-full h-[100vh] flex flex-col gap-5 justify-center items-center bg-red-500 text-white ">
      <h2 className=" text-4xl">We have encountered an Error:</h2>
      <div className=" font-bold text-xl">
        {error?.message ? error?.message : error}
      </div>
    </div>
  );
}

export default ErrorPage;
