import { useState } from "react";

import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";

function QAContainer({ qa }) {
  const [open, setOpen] = useState(false);

  return (
    <div
      className=" flex flex-col items-start justify-center gap-2 text-left w-full 
    bg-white p-5 rounded-sm cursor-pointer"
      onClick={() => setOpen(!open)}
    >
      <div className=" flex w-full justify-between items-center font-bold">
        {qa?.ques}&nbsp;
        <div className=" w-fit h-fit">
          {open ? (
            <FaAngleUp className=" text-red-500 cursor-pointer" />
          ) : (
            <FaAngleDown className=" text-red-500 cursor-pointer" />
          )}
        </div>
      </div>
      <div className={``}>{open ? qa.ans : ""}</div>
    </div>
  );
}

export default QAContainer;
