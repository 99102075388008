import useContentFetcher from "../../CustomHooks/ContentFetcher/ContentFetcher";
import ErrorPage from "../../Pages/ErrorPage";
import ContactFormMain from "../ContactFormMain/ContactFormMain";
import UniversalLoader from "../UniversalLoader/UniversalLoader";
import companyDetails from "../../CompanyDetails.js";

import { IoMailOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { GiDirectionSigns } from "react-icons/gi";

import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import useIntersectionObserver from "../../Animations/animationObserver.js";

import ContactRightImg from "../../Assets/HomePage/contact.jpg";

function ContactSection() {
  const { content, contentLoading, contentError } = useContentFetcher("common");

  const leftRef = useIntersectionObserver("animate-slide-left");
  const rightRef = useIntersectionObserver("animate-slide-right");

  if (contentLoading) return <UniversalLoader />;

  if (contentError) return <ErrorPage error={"Could Not Fetch Content!"} />;

  //   console.log("contentcontact:", content);

  return (
    <div
      className=" w-[95%] lg:w-[90%] px-5 py-10 bg-white rounded-lg flex flex-col justify-start 
    items-center gap-10"
    >
      <div
        ref={leftRef}
        className=" w-full flex flex-col lg:flex-row justify-between items-start lg:items-center"
      >
        <h5 className=" font-semibold text-xl">
          {content?.contactSection?.contactInfo}
        </h5>
        <span>
          {content?.contactSection?.location}:&nbsp;{" "}
          {content?.contactSection?.lucknow}{" "}
        </span>
      </div>
      <div className=" w-full h-1 bg-gradient-to-r from-red-500 to-white rounded-lg" />

      <div
        ref={rightRef}
        className=" w-full flex flex-col lg:flex-row gap-5 justify-between items-center"
      >
        <div className=" w-full lg:w-[60%] flex flex-col justify-between items-center gap-10 ">
          <div className=" w-full h-4 bg-gradient-to-r from-red-300 to-white rounded-lg" />

          <ContactFormMain content={content?.contactSection} />
          <div className=" w-full h-4 bg-gradient-to-r from-red-300 to-white rounded-lg" />
        </div>
        <div className=" w-full lg:w-[30%] flex flex-col items-start justify-start">
          <div className=" w-full flex flex-col justify-start items-start gap-5">
            <img
              className=" w-[300px] h-auto rounded-lg"
              src={ContactRightImg}
              alt="Contact"
            />
            <span className=" font-semibold pl-2 border-l-4 border-orange-400">
              {content?.contactSection?.followUs}
            </span>
            {/* <div className=" flex justify-start items-center gap-5">
              <div className=" bg-orange-400 w-fit p-[5px] rounded-md">
                <FaFacebookF className=" text-white  w-5 h-5" />
              </div>
              <div className=" bg-orange-400 w-fit p-[5px] rounded-md">
                <FaInstagram className=" text-white  w-5 h-5" />
              </div>
              <div className=" bg-orange-400 w-fit p-[5px] rounded-md">
                <FaTwitter className=" text-white  w-5 h-5" />
              </div>
              <div className=" bg-orange-400 w-fit p-[5px] rounded-md">
                <FaYoutube className=" text-white  w-5 h-5" />
              </div>
            </div> */}
            <div className=" flex flex-col gap-5 font-semibold">
              <a
                href={`tel:${companyDetails.companyPhone1}`}
                className="flex justify-start items-center gap-2 hover:text-red-500 cursor-pointer"
              >
                <IoCallOutline />
                {companyDetails.companyPhone1}
              </a>
              <a
                href={`mailto:${companyDetails.companyEmail1}`}
                className="flex justify-start items-center gap-2 hover:text-red-500 cursor-pointer "
              >
                <IoMailOutline />
                {companyDetails.companyEmail1}
              </a>
              <a
                href={companyDetails?.copmanyAddresslink}
                target="_blank"
                rel="noopener noreferrer"
                className="flex justify-start items-center gap-2 w-[300px] text-left hover:text-red-500"
              >
                <GiDirectionSigns className="w-8 h-6" />
                <span className="text-sm">{content?.company_address}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
