import useIntersectionObserver from "../../Animations/animationObserver";
import QAContainer from "../QAContainer/QAContainer";

function FaqSection({ content, img, imgRight }) {
  const leftRef = useIntersectionObserver("animate-slide-left");
  const rightRef = useIntersectionObserver("animate-slide-right");

  return (
    <div className=" w-full flex flex-col items-start justify-center bg-white py-5 gap-10 ">
      <div className=" flex justify-center items-center gap-5">
        <img src={img} alt="FAQ" />
        <h6 className=" text-6xl font-semibold text-red-500">{content?.faq}</h6>
      </div>
      <div
        className=" w-full flex flex-col lg:flex-row gap-5 justify-center 
      items-center lg:justify-between lg:items-start lg:px-16"
      >
        <div
          ref={leftRef}
          className=" w-full lg:w-[50%] flex flex-col justify-start items-start gap-5 
        bg-gray-100 rounded-lg p-5"
        >
          {content?.homeFaqContent?.map((qa, index) => (
            <QAContainer qa={qa} key={index} />
          ))}
        </div>
        <img
          ref={rightRef}
          className=" w-[90%] lg:w-[30%] rounded-lg"
          src={imgRight}
          alt="FAQ"
        />
      </div>
    </div>
  );
}

export default FaqSection;
