import useContentFetcher from "../../CustomHooks/ContentFetcher/ContentFetcher";
import ErrorPage from "../../Pages/ErrorPage";
import ProductCard from "../ProductCard/ProductCard";
import UniversalLoader from "../UniversalLoader/UniversalLoader";

import HomeProductsShowcaseBgImg from "../../Assets/HomePage/HomeProductsShowcaseBgImg.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/navigation";

import ProductImg1 from "../../Assets/Products/ProductImg1.png";
import ProductImg2 from "../../Assets/Products/ProductImg2.png";
import ProductImg3 from "../../Assets/Products/ProductImg3.png";
import ProductImg4 from "../../Assets/Products/ProductImg4.png";
import ProductImg5 from "../../Assets/Products/ProductImg5.png";
import ProductImg6 from "../../Assets/Products/ProductImg6.png";
import { useEffect, useState } from "react";

function ProductsShowcase() {
  const { content, contentLoading, contentError } = useContentFetcher(
    "products"
  );

  const [innerWidth, setInnerWidth] = useState(null);

  useEffect(() => {
    setInnerWidth(window?.innerWidth);
  }, [window?.innerWidth]);

  if (contentLoading) return <UniversalLoader />;

  if (contentError) return <ErrorPage error={"Error Fetching Data"} />;

  const products = content?.products;

  // console.log("contentProducts:", content);

  const productImages = [
    ProductImg1,
    ProductImg2,
    ProductImg3,
    ProductImg4,
    ProductImg5,
    ProductImg6,
  ];
  return (
    <div
      style={{ backgroundImage: `url(${HomeProductsShowcaseBgImg})` }}
      className=" bg-cover bg-no-repeat rounded-md px-6 py-16 
      flex flex-col justify-center items-start gap-10 text-left w-[90%] lg:w-[100%]"
    >
      <h3 className=" font-bold text-4xl">{content?.ourProducts}</h3>
      <div className=" flex justify-center items-center w-[100%]">
        <Swiper
          modules={[Navigation]}
          navigation
          spaceBetween={0}
          slidesPerView={innerWidth > 1025 ? 3 : 1}
          className=" w-[80%]"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: innerWidth > 1025 ? "100px" : "0",
            gap: "10px",
          }}
          //   onSlideChange={() => console.log("slide change")}
          //   onSwiper={(swiper) => console.log(swiper)}
        >
          {products?.map((product, index) => (
            <SwiperSlide key={index}>
              <ProductCard
                img={productImages[product?.id]}
                heading={product?.title}
                knowMore={content?.knowMore}
                id={index}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default ProductsShowcase;
