// import gsap from "gsap";

// export const animatePageElements = (ref) => {
//   const elements = ref.current.querySelectorAll("div");

//   elements.forEach((element, index) => {
//     gsap.from(element, {
//       x: index % 2 === 0 ? -50 : 50,
//       opacity: 0,
//       duration: 0.5,
//       delay: index * 0.1,
//       ease: "power3.out",
//     });
//   });
// };

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// export const animatePageElements = (ref) => {
//   const elements = ref.current.querySelectorAll("*");

//   elements.forEach((element, index) => {
//     gsap.from(element, {
//       x: index % 2 === 0 ? -50 : 50,
//       opacity: 0,
//       duration: 0.5,
//       scrollTrigger: {
//         trigger: element,
//         start: "top center",
//         end: "bottom center",
//         toggleActions: "play none none none",
//       },
//     });
//   });
// };

export const genericAnimationLeft = (ref) => {
  gsap.from(ref?.current?.querySelectorAll("div, li, img"), {
    x: 50,
    opacity: 0,
    delay: 0.5,
    duration: 0.5,
    stagger: 0.1,
  });
};

export const animationGenLeftScrollTriggered = (ref) => {
  gsap.from(ref?.current?.children, {
    x: 500,
    opacity: 0,
    duration: 1,
    skewX: 30,
    ease: "back.out(2,2)",
    scrollTrigger: {
      trigger: ref.current,
      start: "top center",
      end: "bottom 80%",
      toggleActions: "play none none none",
      // markers: true,
    },
  });
};

export const animationGenClassLeftScrollTriggered = () => {
  gsap.utils.toArray(".animate-left-class").forEach((g) => {
    gsap.from(g, {
      x: -200,
      opacity: 0,
      duration: 1,
      skewX: -30,
      ease: "back.out(2,2)",
      scrollTrigger: {
        trigger: g,
        start: "top center",
        end: "bottom 80%",
        toggleActions: "play none none none",
        // markers: true,
      },
    });
  });
};

export const animationGenClassRightScrollTriggered = () => {
  gsap.utils.toArray(".animate-right-class").forEach((g) => {
    gsap.from(g, {
      x: 200,
      opacity: 0,
      duration: 1,
      skewX: 30,
      ease: "back.out(2,2)",
      scrollTrigger: {
        trigger: ".animate-left-class",
        start: "top center",
        end: "bottom 80%",
        toggleActions: "play none none none",
        // markers: true,
      },
    });
  });
};
