import { useParams } from "react-router-dom";
import PageHeader from "../Components/PageHeader/PageHeader";
import useContentFetcher from "../CustomHooks/ContentFetcher/ContentFetcher";
import UniversalLoader from "../Components/UniversalLoader/UniversalLoader";
import ErrorPage from "./ErrorPage";
import ProductsFaqSection from "../Components/ProductsFaqSection/ProductsFaqSection";

import ProductImg1 from "../Assets/Products/ProductImg1.png";
import ProductImg2 from "../Assets/Products/ProductImg2.png";
import ProductImg3 from "../Assets/Products/ProductImg3.png";
import ProductImg4 from "../Assets/Products/ProductImg4.png";
import ProductImg5 from "../Assets/Products/ProductImg5.png";
import ProductImg6 from "../Assets/Products/ProductImg6.png";

import Icon1 from "../Assets/Products/Icon1.png";
import Icon2 from "../Assets/Products/Icon2.png";
import Icon3 from "../Assets/Products/Icon3.png";
import Catalogues from "../Components/Catalogues/Catalogues";
import TestRideFormModal from "../Components/TestRideFormModal/TestRideFormModal";

import FinanceIcon from "../Assets/Products/FinanceIcon.png";
import { useState } from "react";
import useIntersectionObserver from "../Animations/animationObserver";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/autoplay";

function SingleProductPage() {
  const zoomInRef = useIntersectionObserver("animate-zoom-in");

  const productImages = [
    ProductImg1,
    ProductImg2,
    ProductImg3,
    ProductImg4,
    ProductImg5,
    ProductImg6,
  ];
  const { id } = useParams();

  const [testRideMoalOpen, setTestRideModalOpen] = useState(false);

  const handleTestRideFormOpen = () => setTestRideModalOpen(true);
  const handleTestRideFormClose = () => setTestRideModalOpen(false);

  const {
    content: productData,
    contentError: productError,
    contentLoading: productLoading,
  } = useContentFetcher("products");

  const { content, contentError, contentLoading } = useContentFetcher(
    "productsPage"
  );

  if (contentLoading) return <UniversalLoader />;

  if (contentError)
    return (
      <ErrorPage
        error={contentError?.message ? contentError?.message : contentError}
      />
    );

  const currentProduct = productData?.products?.find(
    (product) => product?.id === id
  );

  // console.log("currentProduct:", currentProduct);

  const productImg = productImages[currentProduct?.id];

  return (
    <div className=" w-full flex flex-col justify-center items-center gap-10 text-gray-600">
      <div
        className=" mt-[160px] w-[96%] lg:w-[90%] bg-white rounded-lg flex flex-col gap-20 
      justify-center items-start p-5 lg:p-10 text-left"
      >
        <PageHeader
          heading={content?.product}
          subHeading={currentProduct?.title}
          subPage={currentProduct?.title}
        />
        {currentProduct &&
          (currentProduct?.id === "4" ? (
            <>
              {" "}
              <div
                className=" flex flex-col lg:flex-row justify-center items-center 
              lg:justify-between lg:items-start w-full gap-10"
              >
                <div className=" w-full lg:w-[40%] flex flex-col justify-center items-center gap-10">
                  <img
                    style={{ borderRadius: "10px" }}
                    ref={zoomInRef}
                    className=" w-full rounded-lg"
                    src={productImg}
                    alt={currentProduct?.title || "Product"}
                  />

                  {/* <div className=" w-full flex justify-between gap-5 items-center">
                    <div
                      className=" bg-orange-50 w-[100px] h-[100px] p-2 
              flex flex-col justify-center items-center gap-2 font-semibold rounded-lg"
                    >
                      <img src={Icon1} alt="ICON" />
                      {currentProduct?.power}
                    </div>
                    <div
                      className=" bg-orange-50 w-[100px] h-[100px] p-2 
              flex flex-col justify-center items-center gap-2 font-semibold rounded-lg"
                    >
                      <img src={Icon2} alt="ICON" />
                      {currentProduct?.weight}
                    </div>
                    <div
                      className=" bg-orange-50 w-[100px] h-[100px] p-2 
              flex flex-col justify-center items-center gap-2 font-semibold rounded-lg"
                    >
                      <img src={Icon3} alt="ICON" />
                      {currentProduct?.subType}
                    </div>
                  </div> */}
                </div>
                <div className="w-full lg:w-[50%] flex flex-col justify-start items-start gap-5 text-gray-600">
                  <h3 className=" text-2xl font-semibold">
                    {currentProduct?.title}
                  </h3>
                  <h4 className=" font-bold px-5 py-2 bg-red-500 text-white rounded-lg">
                    {content?.features}
                  </h4>
                  <div className=" w-full border border-gray-300 rounded-lg p-5 grid grid-cols-2 text-left">
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.loadCapacity}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.features?.loadCapacity}
                    </div>
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.runningMileage}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.features?.runningMileage}
                    </div>
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.maxSpeed}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.features?.maxSpeed}
                    </div>
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.battery}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.features?.battery}
                    </div>
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.lrSwitch}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.features?.lrSwitch}
                    </div>
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.dimensions}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.features?.dimension}
                    </div>
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.motor}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.features?.motor}
                    </div>
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.controller}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.features?.controller}
                    </div>
                    {/* ----------- */}
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.differential}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.features?.differential}
                    </div>
                    {/* ----------- */}
                    {/* ----------- */}
                  </div>
                  <button
                    className=" bg-transparent text-gray-500 font-semibold border-[2px] 
          border-gray-300 hover:border-transparent hover:bg-red-500 
          hover:text-white shadow-md hover:shadow-red-400 
          px-3 py-1 rounded-lg w-full "
                    onClick={handleTestRideFormOpen}
                  >
                    {productData?.enquireNow}
                  </button>
                </div>
              </div>
              <div className=" w-full flex flex-col justify-start items-start gap-10">
                <h5 className=" text-red-500 font-semibold text-2xl">
                  {content?.subHeading}
                </h5>
                <div className=" w-full text-left flex flex-col justify-start item gap-5">
                  <h4 className=" text-gray-700 text-5xl font-semibold">
                    {currentProduct?.title}
                  </h4>
                  <div className=" text-gray-700 flex flex-col justify-start items-start gap-5">
                    {currentProduct?.productDescription?.map((para, index) => (
                      <p key={index}>{para}</p>
                    ))}
                  </div>
                </div>
              </div>
              <ProductsFaqSection faqs={currentProduct?.faqs} />
            </>
          ) : currentProduct?.id == 5 ? (
            <>
              {" "}
              <div
                className=" flex flex-col lg:flex-row gap-10 lg:justify-center lg:items-center 
              justify-between items-start w-full"
              >
                <div className=" w-full lg:w-[40%] flex flex-col justify-center items-center gap-20">
                  <img
                    className=" w-full rounded-lg"
                    src={productImg}
                    alt={currentProduct?.title || "Product"}
                  />
                  <img
                    className=" w-[60%] rounded-lg"
                    src={FinanceIcon}
                    alt={currentProduct?.title || "Product"}
                  />
                  {/* <div className=" w-full flex justify-between gap-5 items-center">
                  <div
                    className=" bg-orange-50 w-[100px] h-[100px] p-2 
            flex flex-col justify-center items-center gap-2 font-semibold rounded-lg"
                  >
                    <img src={Icon1} alt="ICON" />
                    {currentProduct?.power}
                  </div>
                  <div
                    className=" bg-orange-50 w-[100px] h-[100px] p-2 
            flex flex-col justify-center items-center gap-2 font-semibold rounded-lg"
                  >
                    <img src={Icon2} alt="ICON" />
                    {currentProduct?.weight}
                  </div>
                  <div
                    className=" bg-orange-50 w-[100px] h-[100px] p-2 
            flex flex-col justify-center items-center gap-2 font-semibold rounded-lg"
                  >
                    <img src={Icon3} alt="ICON" />
                    {currentProduct?.subType}
                  </div>
                </div> */}
                </div>
                <div className=" w-full lg:w-[50%] flex flex-col justify-start items-start gap-5 text-gray-600">
                  <h3 className=" text-2xl font-semibold">
                    {currentProduct?.title}
                  </h3>
                  <h4 className=" font-bold px-5 py-2 bg-red-500 text-white rounded-lg">
                    {content?.features}
                  </h4>
                  <div className=" w-full border border-gray-300 rounded-lg p-5 grid grid-cols-2 text-left">
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.batteryCapacity}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.batteryCapacity}
                    </div>
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.chargingVoltage}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.chargingVoltage}
                    </div>
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.nominalVoltage}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.nominalVoltage}
                    </div>
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.bms}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.bms}
                    </div>
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.relay}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.relay}
                    </div>
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.soc}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.soc}
                    </div>
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.weight}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.weight}
                    </div>
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.gps}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.gps}
                    </div>
                    {/* ----------- */}
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.cells}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.cells}
                    </div>
                    {/* ----------- */}
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.mileage}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.mileage}
                    </div>
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.warranty}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.warranty}
                    </div>
                    {/* ----------- */}
                  </div>
                  <button
                    className=" bg-transparent text-gray-500 font-semibold border-[2px] 
        border-gray-300 hover:border-transparent hover:bg-red-500 
        hover:text-white shadow-md hover:shadow-red-400 
        px-3 py-1 rounded-lg w-full "
                    onClick={handleTestRideFormOpen}
                  >
                    {productData?.enquireNow}
                  </button>
                </div>
              </div>
              <div className=" w-full flex flex-col justify-start items-start gap-10">
                <h5 className=" text-red-500 font-semibold text-2xl">
                  {content?.subHeading}
                </h5>
                <div className=" w-full text-left flex flex-col justify-start item gap-5">
                  <h4 className=" text-gray-700 text-5xl font-semibold">
                    {currentProduct?.title}
                  </h4>
                  <div className=" text-gray-700 flex flex-col justify-start items-start gap-5">
                    {currentProduct?.productDescription?.map((para, index) => (
                      <p key={index}>{para}</p>
                    ))}
                  </div>
                </div>
              </div>
              <ProductsFaqSection faqs={currentProduct?.faqs} />
            </>
          ) : (
            <>
              {" "}
              <div className=" flex flex-col lg:flex-row justify-between items-start w-full">
                <div className=" w-full lg:w-[40%] flex flex-col justify-center items-center gap-10">
                  <div className=" w-full">
                    <Swiper
                      modules={[Autoplay]}
                      spaceBetween={0}
                      autoplay={{ delay: 3000, disableOnInteraction: false }}
                      slidesPerView={1}
                      // onSlideChange={() => console.log("slide change")}
                      // onSwiper={(swiper) => console.log(swiper)}
                    >
                      <SwiperSlide>
                        {" "}
                        <img
                          className=" w-full rounded-lg max-h-[450px] object-contain"
                          src={productImg}
                          alt={currentProduct?.title || "Product"}
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        {" "}
                        <img
                          className=" w-full rounded-lg max-h-[450px] object-contain"
                          src={productImg}
                          alt={currentProduct?.title || "Product"}
                        />
                      </SwiperSlide>
                      {/* <SwiperSlide>{slide5}</SwiperSlide> */}
                    </Swiper>
                  </div>

                  <div className=" w-full flex justify-between gap-5 items-center">
                    <div
                      className=" bg-orange-50 w-[100px] h-[100px] p-2 
                       flex flex-col justify-center items-center gap-2 font-semibold rounded-lg"
                    >
                      <img src={Icon1} alt="ICON" />
                      <span className=" text-xs whitespace-nowrap font-bold">
                        {currentProduct?.power}
                      </span>
                    </div>
                    <div
                      className=" bg-orange-50 w-[100px] h-[100px] p-2 
                         flex flex-col justify-center items-center gap-2 font-semibold rounded-lg"
                    >
                      <img src={Icon2} alt="ICON" />
                      <span className=" text-xs whitespace-nowrap font-bold">
                        {currentProduct?.weight}
                      </span>
                    </div>
                    <div
                      className=" bg-orange-50 w-[100px] h-[100px] p-2 
                          flex flex-col justify-center items-center gap-2 font-semibold rounded-lg"
                    >
                      <img src={Icon3} alt="ICON" />
                      <span className=" text-xs whitespace-nowrap font-bold">
                        {currentProduct?.technicalSpecification?.mileage}
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" w-full lg:w-[50%] flex flex-col justify-start items-start gap-5 text-gray-600">
                  <h3 className=" text-2xl font-semibold">
                    {currentProduct?.title}
                  </h3>
                  <h4 className=" font-bold px-5 py-2 bg-red-500 text-white rounded-lg">
                    {content?.specialFeatures}
                  </h4>
                  <div className=" w-full border border-gray-300 rounded-lg p-5 grid grid-cols-2 text-left">
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.frontGlassWithName}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.specialFeatures?.frontGlassWithName}
                    </div>
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.musicSystem}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.specialFeatures?.musicSystem}
                    </div>
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.centralLockSystem}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.specialFeatures?.centralLockSystem}
                    </div>
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.rearShocker}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.specialFeatures?.rearShocker}
                    </div>
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.roof}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.specialFeatures?.roof}
                    </div>
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.wheel}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.specialFeatures?.wheel}
                    </div>
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.curtain}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.specialFeatures?.curtain}
                    </div>
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.toolKit}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.specialFeatures?.toolKit}
                    </div>
                    {/* ----------- */}
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.stepneyCover}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.specialFeatures?.stepneyCover}
                    </div>
                    {/* ----------- */}
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.floorMat}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.specialFeatures?.floorMat}
                    </div>
                    {/* ----------- */}
                    <div className="p-2 font-bold border-b border-gray-300 border-r">
                      {content?.luggageCarrier}
                    </div>
                    <div className="p-2 border-b border-gray-300 font-semibold">
                      {currentProduct?.specialFeatures?.luggageCarrier}
                    </div>
                  </div>
                  <button
                    className=" bg-transparent text-gray-500 font-semibold border-[2px] 
          border-gray-300 hover:border-transparent hover:bg-red-500 
          hover:text-white shadow-md hover:shadow-red-400 
          px-3 py-1 rounded-lg w-full "
                    onClick={handleTestRideFormOpen}
                  >
                    {productData?.enquireNow}
                  </button>
                </div>
              </div>
              <div className=" w-full flex flex-col justify-start items-start gap-10">
                <h5 className=" text-red-500 font-semibold text-2xl">
                  {content?.subHeading}
                </h5>
                <div className=" w-full text-left flex flex-col justify-start item gap-5">
                  <h4 className=" text-gray-700 text-5xl font-semibold">
                    {currentProduct?.title}
                  </h4>
                  <div className=" text-gray-700 flex flex-col justify-start items-start gap-5">
                    {currentProduct?.productDescription?.map((para, index) => (
                      <p key={index}>{para}</p>
                    ))}
                  </div>
                </div>
              </div>
              <div className=" w-full flex flex-col justify-start items-start gap-5">
                <h4 className=" text-2xl font-bold px-5 py-2 bg-red-500 text-white rounded-lg">
                  {content?.technicalFeatures}
                </h4>
                <div className=" w-full border border-gray-300 rounded-lg p-5 grid grid-cols-2 text-left">
                  {/* ----------- */}
                  <div className="p-2 font-bold border-b border-gray-300 border-r">
                    {content?.mileage}
                  </div>
                  <div className="p-2 border-b border-gray-300 font-semibold">
                    {currentProduct?.technicalSpecification?.mileage}
                  </div>
                  {/* ----------- */}
                  <div className="p-2 font-bold border-b border-gray-300 border-r">
                    {content?.seatCapacity}
                  </div>
                  <div className="p-2 border-b border-gray-300 font-semibold">
                    {currentProduct?.technicalSpecification?.seatCapacity}
                  </div>
                  <div className="p-2 font-bold border-b border-gray-300 border-r">
                    {content?.motor}
                  </div>
                  <div className="p-2 border-b border-gray-300 font-semibold">
                    {currentProduct?.technicalSpecification?.motor}
                  </div>
                  <div className="p-2 font-bold border-b border-gray-300 border-r">
                    {content?.motorInput}
                  </div>
                  <div className="p-2 border-b border-gray-300 font-semibold">
                    {currentProduct?.technicalSpecification?.motorInput}
                  </div>
                  <div className="p-2 font-bold border-b border-gray-300 border-r">
                    {content?.controller}
                  </div>
                  <div className="p-2 border-b border-gray-300 font-semibold">
                    {currentProduct?.technicalSpecification?.controller}
                  </div>
                  <div className="p-2 font-bold border-b border-gray-300 border-r">
                    {content?.converter}
                  </div>
                  <div className="p-2 border-b border-gray-300 font-semibold">
                    {currentProduct?.technicalSpecification?.converter}
                  </div>
                  <div className="p-2 font-bold border-b border-gray-300 border-r">
                    {content?.wheelSize}
                  </div>
                  <div className="p-2 border-b border-gray-300 font-semibold">
                    {currentProduct?.technicalSpecification?.wheelSize}
                  </div>
                  {/* ----------- */}
                  <div className="p-2 font-bold border-b border-gray-300 border-r">
                    {content?.dimension}
                  </div>
                  <div className="p-2 border-b border-gray-300 font-semibold">
                    {currentProduct?.technicalSpecification?.dimension}
                  </div>
                  {/* ----------- */}
                  {/* ----------- */}
                  <div className="p-2 font-bold border-b border-gray-300 border-r">
                    {content?.differential}
                  </div>
                  <div className="p-2 border-b border-gray-300 font-semibold">
                    {currentProduct?.technicalSpecification?.differential}
                  </div>
                  {/* ----------- */}
                  {/* ----------- */}
                  <div className="p-2 font-bold border-b border-gray-300 border-r">
                    {content?.frontShocker}
                  </div>
                  <div className="p-2 border-b border-gray-300 font-semibold">
                    {currentProduct?.technicalSpecification?.frontShocker}
                  </div>
                  {/* ----------- */}
                  <div className="p-2 font-bold border-b border-gray-300 border-r">
                    {content?.warring}
                  </div>
                  <div className="p-2 border-b border-gray-300 font-semibold">
                    {currentProduct?.technicalSpecification?.warring}
                  </div>
                  {/* ----------- */}
                  <div className="p-2 font-bold border-b border-gray-300 border-r">
                    {content?.motorController}
                  </div>
                  <div className="p-2 border-b border-gray-300 font-semibold">
                    {currentProduct?.technicalSpecification?.motorController}
                  </div>
                  {/* ----------- */}
                  <div className="p-2 font-bold border-b border-gray-300 border-r">
                    {content?.indicator}
                  </div>
                  <div className="p-2 border-b border-gray-300 font-semibold">
                    {currentProduct?.technicalSpecification?.indicator}
                  </div>
                </div>
              </div>
              <ProductsFaqSection faqs={currentProduct?.faqs} />
            </>
          ))}
        <Catalogues />
        <TestRideFormModal
          open={testRideMoalOpen}
          handleClose={handleTestRideFormClose}
        />
      </div>
    </div>
  );
}

export default SingleProductPage;
