import SmallInfocard from "../SmallInfocard/SmallInfocard";
import BannerCardImg1 from "../../Assets/HomePage/BannerCardImg1.png";
import BannerCardImg2 from "../../Assets/HomePage/BannerCardImg2.png";
import BannerCardImg3 from "../../Assets/HomePage/BannerCardImg3.png";

import HomeIcon1 from "../../Assets/HomePage/HomeIcon1.png";
import HomeIcon2 from "../../Assets/HomePage/HomeIcon2.png";
import HomeIcon3 from "../../Assets/HomePage/HomeIcon3.png";
import { useEffect, useRef } from "react";
import { useGSAP } from "@gsap/react";
import { animationGenLeftScrollTriggered } from "../../Animations/animations";

function HomeBannerCards({ cardsContent }) {
  const cardsRef = useRef();
  const { contextSafe } = useGSAP();

  const cardsAnimation = contextSafe(() => {
    animationGenLeftScrollTriggered(cardsRef);
  });

  useEffect(() => {
    cardsAnimation();
  }, []);
  return (
    <div
      ref={cardsRef}
      className=" flex justify-center items-center flex-wrap w-[90%] gap-5 mt-5 "
    >
      <SmallInfocard
        img={HomeIcon1}
        heading={cardsContent?.cardHeading1}
        subText={cardsContent?.cardSubText1}
      />
      <div className=" hidden lg:flex w-1 h-[250px] rounded-lg bg-gradient-to-t from-red-400 to-red-600"></div>
      <SmallInfocard
        img={HomeIcon2}
        heading={cardsContent?.cardHeading2}
        subText={cardsContent?.cardSubText2}
      />
      <div className=" hidden lg:flex w-1 h-[250px] rounded-lg bg-gradient-to-t from-red-400 to-red-600"></div>

      <SmallInfocard
        img={HomeIcon3}
        heading={cardsContent?.cardHeading3}
        subText={cardsContent?.cardSubText3}
      />
    </div>
  );
}

export default HomeBannerCards;
