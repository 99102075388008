import { useEffect, useState } from "react";

import emailjs from "@emailjs/browser";

function ContactFormMain({ content }) {
  const [inputs, setInputs] = useState(null);

  const [formSuccess, setFormSuccess] = useState(null);
  const [formError, setFormError] = useState(null);

  const [counter, setCounter] = useState(10);

  const handleChange = (event) => {
    const name = event?.target?.name;
    const value = event?.target?.value;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const startCounter = () => {
    setCounter(10);
  };

  useEffect(() => {
    let timerId;
    if (formSuccess === "Success" || formSuccess === "Failed") {
      timerId = setInterval(() => {
        if (counter > 0) {
          setCounter((prevCounter) => prevCounter - 1);
        } else {
          clearInterval(timerId);
        }
      }, 1000);
    }

    return () => clearInterval(timerId);
  }, [counter, formSuccess]);

  useEffect(() => {
    handleChange({ target: { name: "formType", value: "Contact Form" } });
  }, []);

  emailjs.init({
    publicKey: "B_lPSF3qPyIfInJVK",
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // console.log("inputs:", inputs);

    setFormSuccess("Pending");

    emailjs
      .send("service_ncfnito", "template_qrgmrnm", inputs)
      .then(() => {
        setTimeout(() => {
          setFormSuccess("Success");
        }, 2000);
      })
      .catch((error) => {
        setTimeout(() => {
          setFormSuccess("Failed");
          setFormError(error);
          console.log("error:", error);
        }, 2000);
      })
      .finally(() => {
        startCounter();
        setTimeout(() => {
          setFormSuccess(null);
          setFormError(null);
          setCounter(10);
          // handleClose();
        }, 14000);
      });
  };
  return (
    <div className=" w-full ">
      {formSuccess === null && (
        <form
          onSubmit={handleFormSubmit}
          className=" w-full flex flex-col items-start justify-center gap-5"
        >
          <input
            className=" pl-3 outline-none border-2 px-2 py-2 rounded-lg w-full"
            name="Name"
            placeholder={content?.yourName}
            type="text"
            required
            onChange={handleChange}
          />
          <input
            className=" pl-3 outline-none border-2 px-2 py-2 rounded-lg w-full"
            name="PhoneNumber"
            placeholder={content?.phoneNumber}
            type="text"
            required
            onChange={handleChange}
          />
          <input
            className=" pl-3 outline-none border-2 px-2 py-2 rounded-lg w-full"
            name="Email"
            placeholder={content?.emailAddress}
            type="email"
            required
            onChange={handleChange}
          />
          <textarea
            className=" pl-3 outline-none border-2 px-2 py-2 rounded-lg w-full"
            name="Message"
            placeholder={content?.message}
            required
            onChange={handleChange}
          />
          <button
            type="submit"
            className=" px-3 py-1 border-2 border-gray-600 rounded-lg text-gray-600 font-semibold
           hover:bg-red-500 hover:text-white hover:border-white"
          >
            {content?.bookTestDrive}
          </button>
        </form>
      )}
      {formSuccess === "Pending" && formError === null ? (
        <div className=" flex flex-col justify-center items-center gap-5">
          <h6 className=" text-gray-600 font-semibold text-3xl">
            {content?.errorMessages?.sending}
          </h6>
          <div className=" w-10 h-10 border-b-2 border-red-500 rounded-full animate-spin" />
          <span className=" font-semibold text-gray-600 text-3xl">
            {content?.waitMessage}
          </span>
        </div>
      ) : formSuccess === "Success" && formError === null ? (
        <div className=" flex flex-col justify-center items-center gap-5">
          <h6 className=" text-green-500 font-semibold text-3xl">
            {content?.successMessages?.submittedSuccessfully}
          </h6>
          <span className=" font-semibold text-gray-600 text-xl">
            {content?.successMessages?.closingWindow} {counter} &nbsp;
            {content?.seconds}
          </span>
        </div>
      ) : formSuccess === "Failed" && formError ? (
        <div className=" flex flex-col justify-center items-center gap-5">
          <h6 className=" text-gray-500 font-semibold text-3xl">
            {content?.errorMessages?.encounteredError}
          </h6>
          <span className=" text-red-500 font-semibold text-3xl">
            {formError?.message ? formError?.message : formError}
          </span>
          <span className=" font-semibold text-gray-600 text-xl">
            {content?.successMessages?.closingWindow} {counter} &nbsp;
            {content?.seconds}
          </span>
        </div>
      ) : (
        <div className=" h-2 w-auto"></div>
      )}
    </div>
  );
}

export default ContactFormMain;
