import { useEffect, useState } from "react";
import useContentFetcher from "../../CustomHooks/ContentFetcher/ContentFetcher";
import ErrorPage from "../../Pages/ErrorPage";
import DealerCard from "../DealerCard/DealerCard";
import UniversalLoader from "../UniversalLoader/UniversalLoader";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

import DealerLocatorRightImg from "../../Assets/HomePage/DealerLocatorRightImg.png";

import MarqueeRightImg1 from "../../Assets/HomePage/MarqueeImages/MarqueeRightImg1.png";
import MarqueeRightImg2 from "../../Assets/HomePage/MarqueeImages/MarqueeRightImg2.png";
import MarqueeRightImg3 from "../../Assets/HomePage/MarqueeImages/MarqueeRightImg3.png";

import MarqueeLeftImg1 from "../../Assets/HomePage/MarqueeImages/MarqueeLeftImg1.png";
import MarqueeLeftImg2 from "../../Assets/HomePage/MarqueeImages/MarqueeLeftImg2.png";
import MarqueeLeftImg3 from "../../Assets/HomePage/MarqueeImages/MarqueeLeftImg3.png";
import MarqueeLeftImg4 from "../../Assets/HomePage/MarqueeImages/MarqueeLeftImg4.png";

function DealershipLocator({ handleTestRideFormOpen }) {
  const { i18n } = useTranslation();

  const [selectedCity, setSelectedCity] = useState(
    i18n?.language === "en" ? "Nagram, Lucknow" : "नगराम, लखनऊ"
  );

  const [currentDealers, setCurrentDealers] = useState(null);

  const { content, contentLoading, contentError } = useContentFetcher(
    "dealers"
  );

  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    if (selectedCity && content?.dealers) {
      const filteredDealers = content?.dealers.filter((dealer) => {
        // console.log(dealer);
        return dealer?.city === selectedCity;
      });

      setCurrentDealers(filteredDealers);
    }
  }, [selectedCity, content?.dealers]);

  const handleCityChange = (e) => {
    setAnimation(true);
    setTimeout(() => {
      setSelectedCity(e?.target?.value);
      setAnimation(false);
    }, 500);
  };

  if (contentLoading) return <UniversalLoader />;

  if (contentError)
    return (
      <ErrorPage
        error={contentError?.message ? contentError?.message : contentError}
      />
    );

  const citiesForDropdown = content?.dealers?.map((dealer) => dealer?.city);

  return (
    <div
      className=" w-full flex gap-y-5 justify-between items-center
      h-[450px] bg-gradient-to-r from-white to-orange-200 border-2 border-orange-300 rounded-lg overflow-hidden "
    >
      <div
        className=" w-full h-full lg:w-[50%] flex flex-col justify-center items-center 
        lg:items-start lg:justify-start gap-5 lg:pl-10 text-left py-5"
      >
        <div
          className=" w-full flex flex-col justify-center items-center 
        lg:items-start lg:justify-start gap-5"
        >
          <h3 className=" w-[90%] lg:w-full text-red-600 text-3xl lg:text-5xl font-bold">
            {content?.findADealer}
          </h3>
          <select
            className=" max-h-[50px] px-5 py-2 lg:text-2xl outline-none rounded-full 
              font-bold flex justify-center items-center bg-transparent
               text-orange-500 border-2 border-orange-500 cursor-pointer
              "
            onChange={handleCityChange}
            defaultValue={selectedCity}
          >
            {citiesForDropdown?.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div
          className={`w-[50px] h-[50px] border-b-2 border-red-600 rounded-full animate-spin ${
            animation ? "flex" : " hidden"
          } `}
        />
        <div
          className={`w-full flex flex-wrap justify-center lg:justify-start items-center ${
            animation ? "hidden" : ""
          } `}
        >
          {currentDealers &&
            currentDealers?.map((dealer, index) => (
              <DealerCard
                key={index}
                firmName={dealer?.firmName}
                city={dealer?.city}
                handleTestRideFormOpen={handleTestRideFormOpen}
                bookTestRideText={content?.bookTestRide}
              />
            ))}
        </div>
      </div>
      <div className=" w-[40%] h-full hidden lg:block">
        <div
          className="grid grid-cols-2 sm:grid xs:hidden w-full h-full "
          style={{
            transform: "rotate(25deg) translateX(-35%) translateY(20%)",
          }}
        >
          <span className="w-full">
            <Marquee
              direction="up"
              autoFill={true}
              pauseOnHover={true}
              autoWidth={true}
              className="w-full"
            >
              <img
                src={MarqueeLeftImg1}
                alt="img"
                className="w-full object-cover aspect-square"
              />
              <img
                src={MarqueeLeftImg2}
                alt="img"
                className="w-full object-cover aspect-square"
              />
              <img
                src={MarqueeLeftImg3}
                alt="img"
                className="w-full object-cover aspect-square"
              />
              <img
                src={MarqueeLeftImg4}
                alt="img"
                className="w-full object-cover aspect-square"
              />
            </Marquee>
          </span>{" "}
          <span className="w-full">
            <Marquee
              direction="down"
              autoFill={true}
              pauseOnHover={true}
              autoWidth={true}
              className="w-full"
            >
              <img
                src={MarqueeRightImg1}
                alt="img"
                className="w-full object-cover aspect-square"
              />
              <img
                src={MarqueeRightImg2}
                alt="img"
                className="w-full object-cover aspect-square"
              />
              <img
                src={MarqueeRightImg3}
                alt="img"
                className="w-full object-cover aspect-square"
              />
            </Marquee>
          </span>{" "}
        </div>
      </div>
    </div>
  );
}

export default DealershipLocator;
