import { Link } from "react-router-dom";
// import img2 from "../../Assets/Products/ProductImg1.png";
import internalLinks from "../../internalLinks";
function ShowroomCard({ img, heading, knowMore, id, city, address }) {
  return (
    <div
      className=" flex flex-col justify-start items-center w-[320px] h-[450px] 
    bg-white rounded-lg p-5 group shadow-lg gap-5"
    >
      <div className=" w-full h-[50%]">
        <img src={img} alt="Product" className="w-full rounded-lg" />
      </div>
      <div className=" flex flex-col gap-5 justify-center items-center w-full">
        <span className=" py-2 px-5 rounded-md shadow-lg text-orange-500 font-semibold self-start">
          {city}
        </span>
        <h4 className=" font-bold">{heading}</h4>
        <span>{address}</span>
        <Link to={`${internalLinks?.showrooms}/${id}`} className=" w-[80%]">
          <button
            className=" bg-transparent text-gray-500 font-semibold border-[2px] 
          border-gray-300 group-hover:border-transparent group-hover:bg-red-500 
          group-hover:text-white shadow-md group-hover:shadow-red-400 
          px-3 py-1 rounded-lg w-[200px]"
          >
            {knowMore}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default ShowroomCard;
