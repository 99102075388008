import { useGSAP } from "@gsap/react";
import {
  animationGenClassLeftScrollTriggered,
  animationGenClassRightScrollTriggered,
} from "../../Animations/animations";
import { useEffect, useState } from "react";
import useIntersectionObserver from "../../Animations/animationObserver";

function MainContentContainer({ content, img, flip }) {
  // console.log("flip", flip);

  const leftRef = useIntersectionObserver("animate-slide-left");
  const rightRef = useIntersectionObserver("animate-slide-right");

  return (
    <div
      className={` flex flex-col ${
        flip ? " lg:flex-row-reverse" : " lg:flex-row"
      }  justify-center items-center gap-10 w-full text-gray-500`}
    >
      <div ref={leftRef} className=" w-full lg:w-[35%] ">
        <img
          className=" rounded-lg w-full h-auto object-fit "
          src={img}
          alt="E-Rickshaw"
        />
      </div>
      <div
        ref={rightRef}
        className=" w-full lg:w-[70%] flex flex-col text-left"
      >
        <h5 className=" text-xl font-bold">{content?.title}</h5>
        <div className="">
          {content?.productDescription?.map((para, index) => (
            <p key={index} className=" text-base">
              {para}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MainContentContainer;
