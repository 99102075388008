import ContactSection from "../Components/ContactSection/ContactSection";
import PageHeader from "../Components/PageHeader/PageHeader";
import UniversalLoader from "../Components/UniversalLoader/UniversalLoader";
import useContentFetcher from "../CustomHooks/ContentFetcher/ContentFetcher";
import ErrorPage from "./ErrorPage";

function ContactUs() {
  const { content, contentLoading, contentError } = useContentFetcher("common");

  if (contentLoading) return <UniversalLoader />;

  if (contentError)
    return (
      <ErrorPage
        error={
          contentError?.message ? contentError : "Could Not Fetch Content!"
        }
      />
    );

  return (
    <div className=" w-full flex flex-col justify-center items-center gap-10 text-gray-600">
      <div
        className=" mt-[160px] w-[96%] lg:w-[90%] bg-white rounded-lg flex flex-col gap-10 
  justify-center items-start p-5 lg:p-10"
      >
        <PageHeader
          heading={content?.contact_us}
          subHeading={content?.contact_us}
        />
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1779.5796369079171!2d80.99427219457618!3d26.866680300000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390afbf643677acf%3A0x25b974431c7308b1!2sParshvnath%20Plaza!5e0!3m2!1sen!2sin!4v1720694287980!5m2!1sen!2sin"
          width="100%"
          height="450"
          className=" rounded-lg"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />
        <div className=" w-full">
          <ContactSection />
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
