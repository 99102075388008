import { Link } from "react-router-dom";
import internalLinks from "../../internalLinks";

import logoIcon from "../../Assets/Logos/logo192.png";

function ProductCard({ img, heading, knowMore, id }) {
  return (
    <div
      className=" flex flex-col justify-end items-center relative w-[300px] lg:w-[320px] h-[350px] 
    bg-white rounded-lg p-5 overflow-hidden group shadow-lg"
    >
      <div className="absolute top-0 left-0 w-full h-[50%]">
        <img
          src={img}
          alt="Product"
          className=" z-10 absolute top-[25%] left-[20%] w-[200px] h-[200px] 
          object-cover rounded-lg border-2 border-red-400"
        />
        <img
          src={logoIcon}
          alt="Logo"
          className=" w-[100px] opacity-35 absolute top-2 right-2"
        />
        <div className="absolute top-0 left-0 w-[200px] h-[180px] rounded-br-full bg-gray-300 group-hover:bg-red-500"></div>
      </div>
      <div className=" flex flex-col gap-5 justify-center items-center w-full">
        <div className=" w-[100%] h-[2px] rounded-xl bg-gradient-to-r from-red-50 to-red-500"></div>
        <h4 className=" font-bold">{heading}</h4>
        <Link
          to={`${internalLinks?.singleProductPage}/${id}`}
          className=" w-[80%]"
        >
          <button
            className=" bg-transparent text-gray-500 font-semibold border-[2px] 
          border-gray-300 group-hover:border-transparent group-hover:bg-red-500 
          group-hover:text-white shadow-md group-hover:shadow-red-400 
          px-3 py-1 rounded-lg w-full"
          >
            {knowMore}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default ProductCard;
