import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import HomeBannerImg1 from "../../Assets/HomePage/HomeBannerImg1.png";
import HomeBannerImg2 from "../../Assets/HomePage/HomeBannerImg2.png";
import HomeBannerImg3 from "../../Assets/HomePage/HomeBannerImg3.jpg";
import HomeBannerImg4 from "../../Assets/HomePage/HomeBannerImg4.jpg";
import HomeBannerImg5 from "../../Assets/HomePage/HomeBannerImg5.jpeg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/autoplay";
import { Link } from "react-router-dom";
import internalLinks from "../../internalLinks";
import { useGSAP } from "@gsap/react";
import { genericAnimationLeft } from "../../Animations/animations";
import useIntersectionObserver from "../../Animations/animationObserver";

function HomePageBanner({ bannerContent, handleTestRideFormOpen }) {
  // ANIMATION

  const bannerRef = useRef();

  const { contextSafe } = useGSAP();

  const animateHeader = contextSafe(() => {
    genericAnimationLeft(bannerRef);
  });

  useEffect(() => {
    animateHeader();
  }, []);

  // ANIMATION

  const slide1 = (
    <div
      ref={bannerRef}
      className="w-full h-[620px] flex justify-center items-center bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${HomeBannerImg1})`,
      }}
    >
      <div className=" w-[90%] lg:w-full flex flex-col gap-5 text-left lg:ml-32 ">
        <h2 className=" text-red-500 text-3xl lg:text-6xl font-bold leading-3">
          {bannerContent?.heading1a}
        </h2>
        <h2
          className=" text-4xl lg:text-6xl font-bold text-gray-800 w-[50%]"
          style={{
            lineHeight: `${window.innerWidth > 1025 ? "80px" : "50px"}`,
          }}
        >
          {bannerContent?.heading1b}
        </h2>
        <p className=" text-gray-800 font-bold w-full lg:w-[50%]">
          {bannerContent?.subText1}
        </p>
        <div className=" flex gap-5 flex-col lg:flex-row">
          <Link to={internalLinks?.products}>
            <button
              className=" w-[200px] py-1 h-[36px] rounded-lg text-white font-bold bg-gradient-to-r from-orange-500 to-orange-400
               hover:text-white hover:bg-gradient-to-r hover:from-red-500 hover:to-red-500 "
            >
              {bannerContent?.discoverMore}
            </button>
          </Link>
          <Link to={""}>
            <button
              onClick={handleTestRideFormOpen}
              className=" w-[200px] py-1 px-5 rounded-lg text-gray-800 font-bold bg-transparent 
              border-2 border-gray-600 hover:bg-red-500 hover:text-white hover:border-none"
            >
              {bannerContent?.requestACall}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );

  const slide2 = (
    <div
      className=" w-full h-[620px] flex justify-center items-center bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${HomeBannerImg2})`,
      }}
    >
      <div className=" w-[90%] lg:w-full flex flex-col gap-5 text-left lg:ml-32 ">
        <h2 className=" text-red-500 text-3xl lg:text-6xl font-bold leading-4">
          {bannerContent?.heading2a}
        </h2>
        <h2
          className=" text-4xl lg:text-6xl font-bold text-gray-800 w-[50%] leading-loose"
          style={{
            lineHeight: `${window.innerWidth > 1025 ? "80px" : "50px"}`,
          }}
        >
          {bannerContent?.heading2b}
        </h2>
        <p className=" text-gray-800 font-bold w-full lg:w-[50%]">
          {bannerContent?.subText2}
        </p>
        <div className=" flex gap-5 flex-col lg:flex-row">
          <Link to={internalLinks?.products}>
            <button
              className=" w-[200px] py-1 h-[36px] rounded-lg text-white font-bold bg-gradient-to-r from-orange-500 to-orange-400
               hover:text-white hover:bg-gradient-to-r hover:from-red-500 hover:to-red-500 "
            >
              {bannerContent?.discoverMore}
            </button>
          </Link>
          <Link to={""}>
            <button
              onClick={handleTestRideFormOpen}
              className=" w-[200px] py-1 px-5 rounded-lg text-gray-800 font-bold bg-transparent border-2 border-gray-600
             hover:bg-red-500 hover:text-white hover:border-none"
            >
              {bannerContent?.requestACall}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );

  const slide3 = (
    <div
      className=" text-transparent w-full h-[300px] md:h-[600px] lg:h-[620px] flex justify-center items-center bg-contain lg:bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${HomeBannerImg3})`,
      }}
    >
      Hello
    </div>
  );
  const slide4 = (
    <div
      className=" text-transparent w-full h-[300px] md:h-[600px] lg:h-[620px] flex justify-center items-center bg-contain lg:bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${HomeBannerImg4})`,
      }}
    >
      HEllo
    </div>
  );
  const slide5 = (
    <div
      className=" text-transparent w-full h-[300px] md:h-[600px] lg:h-[620px] flex justify-center items-center bg-contain lg:bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${HomeBannerImg5})`,
      }}
    >
      Hello
    </div>
  );
  return (
    <div className=" flex justify-start items-start w-full mt-[109px]">
      <Swiper
        modules={[Autoplay]}
        spaceBetween={0}
        autoplay={{ delay: 7000, disableOnInteraction: false }}
        slidesPerView={1}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>{slide3}</SwiperSlide>
        <SwiperSlide>{slide4}</SwiperSlide>
        <SwiperSlide>{slide5}</SwiperSlide>
      </Swiper>
    </div>
  );
}

export default HomePageBanner;
