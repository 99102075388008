import UniversalLoader from "../Components/UniversalLoader/UniversalLoader";
import useContentFetcher from "../CustomHooks/ContentFetcher/ContentFetcher";
import ErrorPage from "./ErrorPage";

import AboutInfoContainerRightImg from "../Assets/AboutUs/AboutInfoContainerRightImg.png";
import AboutInfoContainerBottomImg from "../Assets/Showrooms/ShowroomImg1.png";
import ServicesImg1 from "../Assets/AboutUs/ServicesImg1.png";
import ServicesImg2 from "../Assets/AboutUs/ServicesImg2.png";
import ServicesImg3 from "../Assets/AboutUs/ServicesImg3.png";
import TeamBgImg from "../Assets/AboutUs/TeamBgImg.png";
import TeamImg1 from "../Assets/AboutUs/TeamImg1.jpg";
import TeamImg2 from "../Assets/AboutUs/TeamImg2.jpeg";
import TeamImg3 from "../Assets/AboutUs/TeamImg3.jpg";
import TeamImg4 from "../Assets/AboutUs/TeamImg4.jpg";
import TeamImg5 from "../Assets/AboutUs/TeamImg5.jpeg";
import TeamImg6 from "../Assets/AboutUs/TeamImg6.jpg";

import AboutIntroRightImg from "../Assets/AboutUs/AboutIntroRightImg.jpg";

import AboutMissionRightImg from "../Assets/AboutUs/AboutMissionRightImg.png";

import APJ from "../Assets/AboutUs/APJ.webp";

import PageHeader from "../Components/PageHeader/PageHeader";

import CompanyLogo from "../Assets/Logos/CompanyLogo1.png";
import CompanyLogo2 from "../Assets/Logos/logo192.png";
import { FaSeedling } from "react-icons/fa6";
import useIntersectionObserver from "../Animations/animationObserver";

function AboutUs() {
  const { content, contentLoading, contentError } = useContentFetcher(
    "aboutUs"
  );

  const floatRef = useIntersectionObserver("animate-float");

  const leftRef = useIntersectionObserver("animate-slide-left");
  const rightRef = useIntersectionObserver("animate-slide-right");
  const leftRef1 = useIntersectionObserver("animate-slide-left");
  const rightRef1 = useIntersectionObserver("animate-slide-right");
  const leftRef2 = useIntersectionObserver("animate-slide-left");
  const rightRef2 = useIntersectionObserver("animate-slide-right");

  const leftRef3 = useIntersectionObserver("animate-slide-left");
  const rightRef3 = useIntersectionObserver("animate-slide-right");

  if (contentLoading) return <UniversalLoader />;

  if (contentError)
    return (
      <ErrorPage
        error={
          contentError?.message ? contentError : "Could Not Fetch Content!"
        }
      />
    );

  const teamImages = [
    TeamImg1,
    TeamImg2,
    TeamImg3,
    TeamImg4,
    TeamImg5,
    TeamImg6,
  ];

  return (
    <div className=" w-full flex flex-col justify-center items-center gap-10 text-gray-600 text-left">
      <div
        className=" mt-[160px] md:w-[90%] bg-white rounded-lg flex flex-col gap-10 
      justify-center items-start p-5 md:p-10"
      >
        <PageHeader
          heading={content?.aboutUs}
          subHeading={content?.mainHeading}
        />

        <div className=" w-full flex flex-col-reverse lg:flex-row gap-y-5 lg:justify-between lg:items-center">
          <div
            ref={leftRef}
            className=" lg:w-[45%] flex flex-col justify-start items-start gap-5"
          >
            <span className=" border-l-4 w-5 font-semibold border-orange-400">
              &nbsp;
            </span>

            <h3 className=" text-3xl font-semibold">
              {content?.infoContainer?.heading}
            </h3>
            <div className=" text-left flex flex-col justify-start items-start gap-5 font-semibold">
              {content?.infoContainer?.paras.map((para, index) => (
                <p key={index}>{para}</p>
              ))}
            </div>
          </div>
          <div
            ref={rightRef}
            className=" lg:w-[50%] flex justify-center items-center"
          >
            <img
              ref={floatRef}
              className=" w-[50%] lg:w-[70%] rounded-lg"
              src={AboutIntroRightImg}
              alt="Info"
            />
          </div>
        </div>
        <img src={AboutInfoContainerBottomImg} className=" w-full" alt="Info" />
      </div>
      <div
        className=" w-[96%] md:w-[90%] bg-white rounded-lg flex flex-col gap-10 
      justify-center items-start p-5 md:p-10"
      >
        <span className=" border-l-4 w-5 font-semibold border-orange-400">
          &nbsp;
        </span>
        <div className=" flex flex-col justify-start items-start">
          <h4 className=" text-3xl font-semibold">
            {content?.mission?.heading}
          </h4>
        </div>
        <div
          ref={leftRef1}
          className=" w-full flex flex-col lg:flex-row-reverse justify-end items-center gap-5"
        >
          <div className=" font-bold text-2xl flex flex-col gap-10">
            <p>{content?.mission?.quote}</p>
            <h5>{content?.mission?.name}</h5>
          </div>
          <img src={AboutMissionRightImg} alt="Mission" />
          {/* <FaSeedling className=" text-red-500" size={100} /> */}
        </div>
        <div
          ref={rightRef1}
          className=" text-left flex flex-col justify-start items-start gap-5 font-semibold"
        >
          {content?.mission?.paras.map((para, index) => (
            <p key={index}>{para}</p>
          ))}
        </div>
        <span className=" border-l-4 w-5 font-semibold border-orange-400">
          &nbsp;
        </span>
        <div className=" flex flex-col justify-start items-start">
          <h4 className=" text-3xl font-semibold">
            {content?.vision?.heading}
          </h4>
        </div>
        <div className=" w-full flex flex-col lg:flex-row-reverse justify-end items-center gap-5">
          <div
            ref={leftRef2}
            className=" font-bold text-2xl flex flex-col gap-10"
          >
            <p>{content?.vision?.quote}</p>
            <h5>{content?.vision?.name}</h5>
          </div>
          <img
            ref={rightRef2}
            className="  flex w-[300px] h-[400px] object-cover rounded-lg"
            alt="APJ Abdul Kalam"
            src={APJ}
          />
        </div>
        <div className=" text-left flex flex-col justify-start items-start gap-5 font-semibold">
          {content?.vision?.paras.map((para, index) => (
            <p key={index}>{para}</p>
          ))}
        </div>
      </div>
      <div
        className=" w-[90%] bg-white rounded-lg flex flex-col flex-wrap gap-10 
      justify-center items-start p-10 text-left"
      >
        <div className=" flex flex-col justify-start items-start">
          <span className=" border-l-4 pl-2 font-semibold border-orange-400">
            {content?.services}
          </span>
          <h2 className=" text-5xl font-semibold">{content?.ourServices}</h2>
        </div>
        <div className=" w-full flex flex-col md:flex-row md:flex-wrap gap-y-5 justify-between items-start">
          <div className=" md:w-[300px] flex flex-col justify-start items-start gap-5 ">
            <img
              className=" bg-red-200 p-2 rounded-lg"
              src={ServicesImg1}
              alt="Services"
            />
            <h5 className=" text-3xl font-semibold textl">
              {content?.training?.heading}
            </h5>
            <div className=" text-left flex flex-col justify-start items-start gap-5 font-semibold">
              {content?.training?.paras.map((para, index) => (
                <p key={index}>{para}</p>
              ))}
            </div>
          </div>
          <div className=" md:w-[300px] flex flex-col justify-start items-start gap-5 ">
            <img
              src={ServicesImg2}
              alt="Services"
              className=" bg-red-200 p-2 rounded-lg"
            />
            <h5 className=" text-3xl font-semibold text-left">
              {content?.spareParts?.heading}
            </h5>
            <div className=" text-left flex flex-col justify-start items-start gap-5 font-semibold">
              {content?.spareParts?.paras.map((para, index) => (
                <p key={index}>{para}</p>
              ))}
            </div>
          </div>
          <div className=" md:w-[300px] flex flex-col justify-start items-start gap-5 ">
            <img
              src={ServicesImg2}
              alt="Services"
              className=" bg-red-200 p-2 rounded-lg"
            />
            <h5 className=" text-3xl font-semibold text-left">
              {content?.credit?.heading}
            </h5>
            <div className=" text-left flex flex-col justify-start items-start gap-5 font-semibold">
              {content?.credit?.paras.map((para, index) => (
                <p key={index}>{para}</p>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ backgroundImage: `url(${TeamBgImg})` }}
        className=" w-full bg-white bg-no-repeat rounded-lg flex flex-col gap-10 
      justify-center items-center p-10 text-gray-800"
      >
        <div
          ref={leftRef3}
          className=" flex flex-col justify-center items-center gap-5"
        >
          <span className=" border-l-4 pl-2 font-semibold border-orange-400">
            {content?.team?.heading}
          </span>
          <h2 className=" text-5xl font-semibold">
            {content?.team?.subHeading}
          </h2>
          <span className=" font-semibold">{content?.team?.subText}</span>
        </div>
        <div
          ref={rightRef3}
          className=" flex justify-center items-center gap-10"
        >
          <div className=" w-full flex-wrap flex justify-center items-center gap-10 gap-y-10">
            {" "}
            {content?.team?.teamMembers?.map((member, index) => (
              <div
                key={index}
                className=" w-[300px] h-[300px] flex flex-col justify-center items-center gap-5 "
              >
                <img
                  src={teamImages[index]}
                  alt="Services"
                  className=" w-[80%] h-[80%] rounded-lg"
                />
                <div
                  className=" w-[80%] flex flex-col justify-center items-center 
                font-semibold bg-gray-900 rounded-lg"
                >
                  <span className=" text-white">{member?.name}</span>
                  <span className=" text-red-500 text-sm">{member?.title}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
