import ShowroomCard from "../Components/ShowroomCard/ShowroomCard";
import UniversalLoader from "../Components/UniversalLoader/UniversalLoader";
import useContentFetcher from "../CustomHooks/ContentFetcher/ContentFetcher";
import ErrorPage from "./ErrorPage";

import ShowroomImg1 from "../Assets/Showrooms/ShowroomImg1.png";
import ShowroomImg2 from "../Assets/Showrooms/ShowroomImg2.png";
import ShowroomImg3 from "../Assets/Showrooms/ShowroomImg3.png";
import ShowroomImg4 from "../Assets/Showrooms/ShowroomImg4.png";
import ShowroomImg5 from "../Assets/Showrooms/ShowroomImg5.png";
import ShowroomImg6 from "../Assets/Showrooms/ShowroomImg6.png";
import ShowroomImg7 from "../Assets/Showrooms/ShowroomImg7.png";
import ShowroomImg8 from "../Assets/Showrooms/ShowroomImg8.png";
import PageHeader from "../Components/PageHeader/PageHeader";

function Showrooms() {
  const { content, contentError, contentLoading } = useContentFetcher(
    "showrooms"
  );

  if (contentError)
    return (
      <ErrorPage
        error={contentError?.message ? contentError?.message : contentError}
      />
    );

  if (contentLoading) return <UniversalLoader />;

  const showroomsData = content?.showroomsData;

  const showroomImages = [
    ShowroomImg1,
    ShowroomImg2,
    ShowroomImg3,
    ShowroomImg4,
    ShowroomImg5,
    ShowroomImg6,
    ShowroomImg7,
    ShowroomImg8,
  ];

  return (
    <div className=" w-full flex flex-col justify-center items-center gap-10 text-gray-600">
      <div
        className=" mt-[160px] w-[90%] bg-white rounded-lg flex flex-col gap-20 
      justify-center items-start p-10"
      >
        <PageHeader
          heading={content?.showrooms}
          subHeading={content?.ourShowrooms}
        />
        <div className=" w-full flex justify-start items-start gap-10 gap-y-20 flex-wrap">
          {showroomsData?.map((showroom, index) => (
            <ShowroomCard
              key={index}
              heading={showroom?.title}
              id={showroom?.id}
              img={showroomImages[showroom?.id]}
              knowMore={content?.knowMore}
              city={showroom?.city}
              address={showroom?.address}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Showrooms;
