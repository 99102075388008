import { Link } from "react-router-dom";
import HomeAboutMainImg from "../../Assets/HomePage/HomeAboutMainImg.jpeg";
import internalLinks from "../../internalLinks";
import { useGSAP } from "@gsap/react";
import {
  animationGenClassLeftScrollTriggered,
  animationGenClassRightScrollTriggered,
} from "../../Animations/animations";

function HomeAboutBox({ content }) {
  useGSAP(
    () => {
      animationGenClassLeftScrollTriggered();
      animationGenClassRightScrollTriggered();
    },
    { scope: ".home-about-box" }
  );

  return (
    <div className=" home-about-box w-[95%] lg:w-[80%] px-2 py-5 gap-10 bg-white rounded-lg flex flex-col lg:flex-row justify-center items-end text-left">
      <div className=" animate-left-class flex flex-col gap-2 lg:w-[40%]">
        <h4 className=" text-orange-400 font-semibold">{content?.aboutUs}</h4>
        <h3 className=" text-3xl text-gray-600 font-semibold">
          {content?.heading}
        </h3>
        <div className=" flex flex-col gap-5 text-gray-500 mt-5">
          <p>{content?.subText}</p>
          <Link to={internalLinks.aboutUs}>
            <button
              className=" w-[150px] px-2 py-1 text-gray-600 border-2 rounded-lg border-gray-600
           hover:bg-red-500 hover:text-white hover:border-white"
            >
              {content?.readOurStory}
            </button>
          </Link>
        </div>
      </div>
      <div className=" animate-right-class lg:w-[50%]">
        <img
          className=" w-full rounded-lg"
          src={HomeAboutMainImg}
          alt="About Us"
        />
      </div>
    </div>
  );
}

export default HomeAboutBox;
