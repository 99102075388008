import { useEffect, useRef } from "react";

const useIntersectionObserver = (
  animationClass,
  options = { threshold: 0.3 }
) => {
  const ref = useRef(null);
  const hasAnimated = useRef(false); // To track if animation has run already

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !hasAnimated.current) {
        entry.target.classList.add(animationClass);
        entry.target.style.visibility = "visible"; // Ensure the element is visible once it animates
        hasAnimated.current = true; // Mark that the animation has been triggered
        observer.unobserve(entry.target); // Stop observing once the animation has run
      }
    }, options);

    const element = ref.current;
    if (element) {
      element.style.visibility = "hidden"; // Hide the element initially
      observer.observe(element);
    }

    return () => {
      if (element) observer.unobserve(element);
    };
  }, [animationClass, options]);

  return ref;
};

export default useIntersectionObserver;
