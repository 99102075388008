import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const useContentFetcher = (page) => {
  const { i18n } = useTranslation();

  const [content, setContent] = useState(null);
  const [contentLoading, setContentLoading] = useState(true);
  const [contentError, setContentError] = useState(null);
  // console.log("i18n.language:", i18n.language);
  useEffect(() => {
    const fetchContent = async () => {
      setContentLoading(true);
      setTimeout(async () => {
        try {
          const response = await axios.get(
            `../locales/${i18n.language}/${page}.json`
          );
          setContent(response?.data);
        } catch (err) {
          setContentError(err);
        } finally {
          setContentLoading(false);
        }
      }, 500);
    };

    fetchContent();
  }, [i18n, i18n.language]);

  return { content, contentLoading, contentError };
};

export default useContentFetcher;
