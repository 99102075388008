import LogoWatermark from "../../Assets/Dealership/LogoWatermark.png";
import MainLogo from "../../Assets/Logos/CompanyLogo1.png";

import companyDetails from "../../CompanyDetails.js";

import { IoMailOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { GiDirectionSigns } from "react-icons/gi";
import useContentFetcher from "../../CustomHooks/ContentFetcher/ContentFetcher.js";
import UniversalLoader from "../UniversalLoader/UniversalLoader";
import ErrorPage from "../../Pages/ErrorPage";
import { useEffect, useRef, useState } from "react";

import emailjs from "@emailjs/browser";

function TestRideForm({ handleClose }) {
  const [inputs, setInputs] = useState(null);

  const [formSuccess, setFormSuccess] = useState(null);
  const [formError, setFormError] = useState(null);

  const [counter, setCounter] = useState(10);

  // const startCounter = () => {
  //   setCounter(10);

  //   const timer = setInterval(() => {
  //     if (counter >= 0) {
  //       setCounter((counter) => counter--);
  //     } else {
  //       clearInterval(timer);
  //     }
  //   }, 1000);

  //   for (let i = 10; i >= 0; i--) {
  //     setTimeout(() => {
  //       setCounter(i);
  //     }, 1000);
  //   }
  // };

  const startCounter = () => {
    setCounter(10);
  };

  useEffect(() => {
    let timerId;
    if (formSuccess === "Success" || formSuccess === "Failed") {
      timerId = setInterval(() => {
        if (counter > 0) {
          setCounter((prevCounter) => prevCounter - 1);
        } else {
          clearInterval(timerId);
          handleClose();
        }
      }, 1000);
    }

    return () => clearInterval(timerId);
  }, [counter, handleClose, formSuccess]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    // console.log("keyvalue:", name, value);

    setInputs((values) => ({ ...values, [name]: value }));
  };

  emailjs.init({
    publicKey: "B_lPSF3qPyIfInJVK",
  });

  useEffect(() => {
    handleChange({ target: { name: "formType", value: "Test Ride" } });
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // console.log("inputs:", inputs);

    setFormSuccess("Pending");

    emailjs
      .send("service_ncfnito", "template_qrgmrnm", inputs)
      .then(() => {
        setTimeout(() => {
          setFormSuccess("Success");
        }, 2000);
      })
      .catch((error) => {
        setTimeout(() => {
          setFormSuccess("Failed");
          setFormError(error);
          console.log("error:", error);
        }, 2000);
      })
      .finally(() => {
        startCounter();
        setTimeout(() => {
          setFormSuccess(null);
          setFormError(null);
          setCounter(10);
          handleClose();
        }, 14000);
      });
  };

  const { content, contentLoading, contentError } = useContentFetcher(
    "testRide"
  );

  if (contentLoading) return <UniversalLoader small={true} />;
  if (contentError)
    return (
      <ErrorPage
        error={
          contentError?.message ? contentError : "Could Not Fetch Content!"
        }
      />
    );

  return (
    <div
      className=" w-[100%] h-auto
      flex flex-col justify-start items-center gap-5 pb-10"
    >
      {formSuccess === null && (
        <>
          <div className=" w-[100%] flex justify-between items-center">
            <div className=" flex flex-col gap-1 font-semibold text-xs">
              <h4 className=" text-xl lg:text-3xl font-semibold text-yellow-500">
                {content?.heading}
              </h4>
              <span>{content?.caption}</span>
            </div>
          </div>
          <div className=" w-[100%] h-[3px] rounded-lg bg-gradient-to-r from-white to-red-500" />
          <div className=" w-full flex flex-col justify-start items-center">
            <form
              onSubmit={handleFormSubmit}
              className=" w-full flex flex-col justify-start items-center gap-5"
            >
              <fieldset className="p-1 border-[1px] border-gray-400 rounded-md w-full">
                <legend className="text-xs px-1 text-gray-500">
                  {content?.formFields?.yourName}
                </legend>
                <input
                  className=" bg-transparent outline-none pl-2 w-full"
                  type="text"
                  name="fullName"
                  onChange={handleChange}
                  required
                  placeholder={content?.formFields?.yourName}
                />
              </fieldset>
              <div className=" flex justify-center items-center w-full gap-5 ">
                <fieldset className="p-1 border-[1px] border-gray-400 rounded-md w-full">
                  <legend className="text-xs px-1 text-gray-500">
                    {content?.formFields?.phoneNumber}
                  </legend>
                  <input
                    className=" bg-transparent outline-none pl-2 w-full"
                    type="tel"
                    pattern="[0-9]{10}"
                    name="phoneNumber"
                    onChange={handleChange}
                    required
                    placeholder={content?.formFields?.phoneNumber}
                  />
                </fieldset>
              </div>
              {/* <h5 className=" flex self-start font-semibold text-gray-500">
                Applicant Structure:
              </h5>
              <div className=" flex justify-start items-center w-full gap-5">
                <div className=" flex justify-center items-center gap-2">
                  <label>{content?.formFields?.company}:</label>
                  <input
                    type="radio"
                    value="Company"
                    name="applicantStructure"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className=" w-[2px] h-6 bg-gray-500 rounded-lg" />
                <div className=" flex justify-center items-center gap-2">
                  <label>{content?.formFields?.partnershipFirm}:</label>
                  <input
                    type="radio"
                    value="Partnership Firm"
                    name="applicantStructure"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className=" w-[2px] h-6 bg-gray-500 rounded-lg" />
                <div className=" flex justify-center items-center gap-2">
                  <label>{content?.formFields?.proprietorship}:</label>
                  <input
                    type="radio"
                    value="Proprietorship"
                    name="applicantStructure"
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <h5 className=" flex self-start font-semibold text-gray-500">
                Location & Address where dealership is sought :
              </h5>
              <fieldset className="p-1 border-[1px] border-gray-400 rounded-md w-full">
                <legend className="text-xs px-1 text-gray-500">
                  {content?.formFields?.address}
                </legend>
                <input
                  className=" bg-transparent outline-none pl-2 w-full"
                  type="text"
                  name="address"
                  onChange={handleChange}
                  required
                  placeholder={content?.formFields?.address}
                />
              </fieldset>
              <div className=" flex justify-center items-center w-full gap-5 ">
                <fieldset className="p-1 border-[1px] border-gray-400 rounded-md w-full">
                  <legend className="text-xs px-1 text-gray-500">
                    {content?.formFields?.state}
                  </legend>
                  <input
                    className=" bg-transparent outline-none pl-2 w-full"
                    type="text"
                    name="state"
                    onChange={handleChange}
                    required
                    placeholder={content?.formFields?.state}
                  />
                </fieldset>
                <fieldset className="p-1 border-[1px] border-gray-400 rounded-md w-full">
                  <legend className="text-xs px-1 text-gray-500">
                    {content?.formFields?.city}
                  </legend>
                  <input
                    className=" bg-transparent outline-none pl-2 w-full"
                    type="text"
                    name="city"
                    onChange={handleChange}
                    required
                    placeholder={content?.formFields?.city}
                  />
                </fieldset>
              </div>
              <div className=" flex justify-center items-center w-full gap-5 ">
                <fieldset className="p-1 border-[1px] border-gray-400 rounded-md w-full">
                  <legend className="text-xs px-1 text-gray-500">
                    {content?.formFields?.landmark}
                  </legend>
                  <input
                    className=" bg-transparent outline-none pl-2 w-full"
                    type="text"
                    name="landmark"
                    onChange={handleChange}
                    required
                    placeholder={content?.formFields?.landmark}
                  />
                </fieldset>
                <fieldset className="p-1 border-[1px] border-gray-400 rounded-md w-full">
                  <legend className="text-xs px-1 text-gray-500">
                    {content?.formFields?.zipCode}
                  </legend>
                  <input
                    className=" bg-transparent outline-none pl-2 w-full"
                    type="number"
                    name="zipCode"
                    onChange={handleChange}
                    required
                    placeholder={content?.formFields?.zipCode}
                  />
                </fieldset>
              </div> */}
              <button
                type="submit"
                className=" px-5 py-1 bg-gray-800 text-white font-semibold rounded-lg"
              >
                {content?.submit}
              </button>
            </form>
          </div>
        </>
      )}
      {formSuccess === "Pending" && formError === null ? (
        <div className=" flex flex-col justify-center items-center gap-5">
          <h6 className=" text-gray-600 font-semibold text-3xl">
            {content?.errorMessages?.sending}
          </h6>
          <div className=" w-10 h-10 border-b-2 border-red-500 rounded-full animate-spin" />
          <span className=" font-semibold text-gray-600 text-3xl">
            {content?.waitMessage}
          </span>
        </div>
      ) : formSuccess === "Success" && formError === null ? (
        <div className=" flex flex-col justify-center items-center gap-5">
          <h6 className=" text-green-500 font-semibold text-3xl">
            {content?.successMessages?.submittedSuccessfully}
          </h6>
          <span className=" font-semibold text-gray-600 text-xl">
            {content?.successMessages?.closingWindow} {counter} &nbsp;
            {content?.seconds}
          </span>
        </div>
      ) : formSuccess === "Failed" && formError ? (
        <div className=" flex flex-col justify-center items-center gap-5">
          <h6 className=" text-gray-500 font-semibold text-3xl">
            {content?.errorMessages?.encounteredError}
          </h6>
          <span className=" text-red-500 font-semibold text-3xl">
            {formError?.message ? formError?.message : formError}
          </span>
          <span className=" font-semibold text-gray-600 text-xl">
            {content?.successMessages?.closingWindow} {counter} &nbsp;
            {content?.seconds}
          </span>
        </div>
      ) : (
        <div className=" h-2 w-auto"></div>
      )}
    </div>
  );
}

export default TestRideForm;

{
  /* <div className=" flex flex-col justify-center items-center">
  <h6 className=" font-semibold text-gray-600">Something went wrong!</h6>
  <span className=" font-semibold text-gray-600">
    This window will close in: {counter}&nbsp;seconds
  </span>
</div>; */
}
