import { useParams } from "react-router-dom";
import useContentFetcher from "../CustomHooks/ContentFetcher/ContentFetcher";
import UniversalLoader from "../Components/UniversalLoader/UniversalLoader";
import ErrorPage from "./ErrorPage";
import PageHeader from "../Components/PageHeader/PageHeader";

import BlogsImg from "../Assets/Blogs/BlogsImg1.png";

function SingleBlogPage() {
  const { id } = useParams();

  const { content, contentLoading, contentError } = useContentFetcher("blogs");

  const currentBlog = content?.blogsContent[id];

  if (contentLoading) return <UniversalLoader />;

  if (contentError)
    return (
      <ErrorPage
        error={
          contentError?.message ? contentError : "Could Not Fetch Content!"
        }
      />
    );

  return (
    <div className=" w-full flex flex-col justify-center items-center gap-10 text-gray-600">
      <div
        className=" mt-[160px] w-[96%] lg:w-[90%] bg-white rounded-lg flex flex-col gap-10 
  justify-center items-start p-5 lg:p-10"
      >
        <PageHeader
          heading={content?.blogs}
          subHeading={currentBlog?.blogs}
          subPage={`Blog - ${currentBlog?.id}`}
        />
        <img className=" w-[100%]" src={BlogsImg} alt="Blogs" />
        <div className=" text-gray-600 font-semibold flex flex-col justify-start items-start gap-5">
          <h2 className=" text-3xl lg:text-4xl text-left lg:w-[60%]">
            {currentBlog?.mainHeading}
          </h2>
          <span className=" text-left">{currentBlog?.date}</span>
        </div>
        <div className=" flex flex-col justify-start items-start gap-5">
          {currentBlog?.paras?.map((para, index) => (
            <div
              key={index}
              className=" flex flex-col justify-start items-start text-left gap-2"
            >
              <h6 className=" font-semibold text-2xl">{para?.heading}</h6>
              <p>{para?.para}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SingleBlogPage;
