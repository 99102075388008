function UniversalLoader({ small }) {
  return (
    <div
      className={`${
        small ? " w-auto h-auto" : "w-full h-[100vh]"
      }  flex flex-col justify-center items-center`}
    >
      Loading...
      <div className=" flex justify-center items-center m-5 w-16 h-16 border-l-2 rounded-full  border-red-600 animate-spin "></div>
    </div>
  );
}

export default UniversalLoader;
