import { Link } from "react-router-dom";
import BlogsImg1 from "../../Assets/Blogs/BlogsImg1.png";
import internalLinks from "../../internalLinks";

function BlogsShowcase({ content }) {
  const renderedBlogsCards = content?.map((blog, index) => (
    <Link to={`${internalLinks.singleBlogPage}/${index}`}>
      <div
        key={index}
        className=" w-[300px] h-[400px] bg-cover flex justify-center items-start
       flex-col text-left p-10 gap-5 text-white font-semibold
        bg-gray-500 bg-blend-multiply rounded-lg
          hover:border-4 hover:border-red-500 cursor-pointer"
        style={{ backgroundImage: `url(${BlogsImg1})` }}
      >
        <span>{blog?.date}</span>
        <h4 className=" text-2xl">{blog?.mainHeading}</h4>
      </div>
    </Link>
  ));

  return (
    <div>
      <div className=" flex justify-start items-start gap-5 flex-wrap">
        {renderedBlogsCards}
      </div>
    </div>
  );
}

export default BlogsShowcase;
