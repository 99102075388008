import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function ProductsFaqSection({ faqs }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className=" w-full flex flex-col justify-start items-start gap-5 text-gray-700 font-semibold">
      <h4 className=" text-3xl">FAQs</h4>
      <div className=" text-left flex flex-col justify-start items-start gap-5 w-full">
        {faqs?.map((faq, index) => (
          <Accordion
            expanded={expanded === index}
            onChange={handleChange(index)}
            sx={{ width: "100%", borderRadius: "10px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                sx={{ width: "100%", flexShrink: 0, fontWeight: "bold" }}
              >
                {faq?.ques}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq?.ans}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}

        {/* ------------------ */}
      </div>
    </div>
  );
}

export default ProductsFaqSection;
