function SmallInfocard({ img, heading, subText }) {
  return (
    <div
      className=" w-[300px] lg:w-[350px] min-h-[300px] bg-gradient-to-r from-red-600 to-red-800 
    px-5 py-10 gap-5 rounded-lg flex flex-col justify-start items-center text-white"
    >
      <img
        src={img}
        className=" p-3 bg-white rounded-[50%] object-contain w-[70px] h-[70px]"
      />
      <div className=" flex flex-col justify-center items-center gap-5">
        <h2 className=" text-lg font-bold">{heading}</h2>
        <p>{subText}</p>
      </div>
    </div>
  );
}

export default SmallInfocard;
