import BlogsShowcase from "../Components/BlogsShowcase/BlogsShowcase";
import PageHeader from "../Components/PageHeader/PageHeader";
import UniversalLoader from "../Components/UniversalLoader/UniversalLoader";
import useContentFetcher from "../CustomHooks/ContentFetcher/ContentFetcher";
import internalLinks from "../internalLinks";
import ErrorPage from "./ErrorPage";

function Blogs() {
  const { content, contentLoading, contentError } = useContentFetcher("blogs");

  if (contentLoading) return <UniversalLoader />;

  if (contentError)
    return (
      <ErrorPage
        error={
          contentError?.message ? contentError : "Could Not Fetch Content!"
        }
      />
    );

  return (
    <div className=" w-full flex flex-col justify-center items-center gap-10 text-gray-600">
      <div
        className=" mt-[160px] w-[90%] bg-white rounded-lg flex flex-col gap-10 
  justify-center items-start p-10"
      >
        <PageHeader heading={content?.blogs} subHeading={content?.blogs} />
        <br />
        <div className=" flex flex-col justify-start items-start">
          <span className="text-sm text-red-500 font-semibold ">
            {content?.ourBlog}
          </span>
          <h2 className=" text-gray-500 text-3xl font-semibold">
            {content?.whatsNew}
          </h2>
        </div>
        <BlogsShowcase content={content?.blogsContent} />
      </div>
    </div>
  );
}

export default Blogs;
