import Catalogues from "../Components/Catalogues/Catalogues";
import PageHeader from "../Components/PageHeader/PageHeader";
import ProductCard from "../Components/ProductCard/ProductCard";
import UniversalLoader from "../Components/UniversalLoader/UniversalLoader";
import useContentFetcher from "../CustomHooks/ContentFetcher/ContentFetcher";

import ProductImg1 from "../Assets/Products/ProductImg1.png";
import ProductImg2 from "../Assets/Products/ProductImg2.png";
import ProductImg3 from "../Assets/Products/ProductImg3.png";
import ProductImg4 from "../Assets/Products/ProductImg4.png";
import ProductImg5 from "../Assets/Products/ProductImg5.png";
import ProductImg6 from "../Assets/Products/ProductImg6.png";

function ProductsPage() {
  const { content, contentError, contentLoading } = useContentFetcher(
    "products"
  );

  if (contentLoading) return <UniversalLoader />;
  if (contentError)
    return contentError?.message ? contentError : "Could Not Fetch Data";

  const productsData = content?.products;

  const productImages = [
    ProductImg1,
    ProductImg2,
    ProductImg3,
    ProductImg4,
    ProductImg5,
    ProductImg6,
  ];
  return (
    <div className=" w-full flex flex-col justify-center items-center gap-10 text-gray-600">
      <div
        className=" mt-[160px] w-[96%] md:w-[90%] bg-white rounded-lg flex flex-col gap-20 
      justify-center items-start p-5 md:p-10"
      >
        <PageHeader heading={"Products"} subHeading={"Products"} />

        <div className=" flex flex-wrap justify-center items-center gap-10 gap-y-20">
          {productsData?.map((data, index) => (
            <ProductCard
              key={index}
              id={data?.id}
              heading={data?.title}
              knowMore={content?.knowMore}
              img={productImages[data?.id]}
            />
          ))}
        </div>
        <Catalogues />
      </div>
    </div>
  );
}

export default ProductsPage;
