import { Link } from "react-router-dom";
import MainLogo from "../../Assets/Logos/CompanyLogo1.png";
import internalLinks from "../../internalLinks";
import useContentFetcher from "../../CustomHooks/ContentFetcher/ContentFetcher";
import UniversalLoader from "../UniversalLoader/UniversalLoader";
import ErrorPage from "../../Pages/ErrorPage";
import companyDetails from "../../CompanyDetails";

import { IoMailOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { GiDirectionSigns } from "react-icons/gi";

import NavTrustedImg from "../../Assets/HomePage/NavTrustedImg.png";

function Footer() {
  const { content, contentLoading, contentError } = useContentFetcher("common");

  if (contentLoading) return <UniversalLoader />;

  if (contentError) return <ErrorPage error="Could Not Fetch Conent!" />;

  return (
    <footer className=" w-full justify-center items-center flex flex-col mt-40 bg-white ">
      <h3 className=" text-5xl text-red-500 bg-white rounded-lg p-5 font-bold">
        सफ़र का दमदार साथी
      </h3>
      <div
        className=" w-full flex flex-wrap gap-10 gap-y-10 justify-center items-center lg:flex-nowrap 
      lg:justify-between lg:items-center p-2 lg:p-20 text-sm "
      >
        <Link to={internalLinks?.home} className=" lg:mr-28">
          <div className="hover:bg-red-700 bg-gray-700 px-12 py-2 rounded-lg">
            <img src={MainLogo} alt="Logo" className=" lg:w-[150px]" />
          </div>
        </Link>

        <div className=" w-full lg:w-[70%] flex flex-wrap justify-center lg:justify-center items-center gap-10 gap-y-5">
          <div className=" flex flex-col justify-center items-center gap-5">
            <img src={NavTrustedImg} alt="Trusted" />
            <h6 className=" font-bold text-gray-700">{content?.quickLinks}</h6>
            <ul className=" flex justify-start items-start flex-wrap gap-5">
              <Link to={internalLinks?.home}>
                <li
                  className=" text-gray-500 font-semibold 
            hover:bg-orange-400 hover:text-white px-2 py-1 rounded-md "
                >
                  {content?.home}
                </li>
              </Link>
              <Link to={internalLinks?.products}>
                <li
                  className=" text-gray-500 font-semibold 
            hover:bg-orange-400 hover:text-white px-2 py-1 rounded-md "
                >
                  {content?.products}
                </li>
              </Link>
              <Link to={internalLinks?.aboutUs}>
                <li
                  className=" text-gray-500 font-semibold 
            hover:bg-orange-400 hover:text-white px-2 py-1 rounded-md "
                >
                  {content?.about_us}
                </li>
              </Link>
              <Link to={internalLinks?.contactUs}>
                <li
                  className=" text-gray-500 font-semibold 
            hover:bg-orange-400 hover:text-white px-2 py-1 rounded-md "
                >
                  {content?.contact_us}
                </li>
              </Link>
              {/* <Link to={internalLinks?.faq}>
                <li
                  className=" text-gray-500 font-semibold 
            hover:bg-orange-400 hover:text-white px-2 py-1 rounded-md "
                >
                  {content?.faq}
                </li>
              </Link> */}
            </ul>
          </div>

          {/* <div className=" text-left">
            <h6 className=" font-bold text-gray-700">{content?.support}</h6>
            <ul>
              <Link to={internalLinks?.customerService}>
                <li
                  className=" text-gray-500 font-semibold 
            hover:bg-orange-400 hover:text-white px-2 py-1 rounded-md "
                >
                  {content?.customerService}
                </li>
              </Link>
              <Link to={internalLinks?.warranty}>
                <li
                  className=" text-gray-500 font-semibold 
            hover:bg-orange-400 hover:text-white px-2 py-1 rounded-md "
                >
                  {content?.warranty}
                </li>
              </Link>
              <Link to={internalLinks?.returns}>
                <li
                  className=" text-gray-500 font-semibold 
            hover:bg-orange-400 hover:text-white px-2 py-1 rounded-md "
                >
                  {content?.returns}
                </li>
              </Link>
              <Link to={internalLinks?.shipping}>
                <li
                  className=" text-gray-500 font-semibold 
            hover:bg-orange-400 hover:text-white px-2 py-1 rounded-md "
                >
                  {content?.shipping}
                </li>
              </Link>
              <Link to={internalLinks?.privacyPolicy}>
                <li
                  className=" text-gray-500 font-semibold 
            hover:bg-orange-400 hover:text-white px-2 py-1 rounded-md "
                >
                  {content?.privacyPolicy}
                </li>
              </Link>
            </ul>
          </div> */}
        </div>

        <div className=" text-left text-gray-500">
          {/* <h6 className=" font-bold text-gray-700">{content?.support}</h6> */}
          <div className=" flex flex-col gap-5 font-semibold">
            <a
              href={`tel:${companyDetails.companyPhone1}`}
              className="flex justify-start items-center gap-2 hover:text-red-500 cursor-pointer"
            >
              {" "}
              <div className=" bg-orange-400 p-1 rounded-full">
                <IoCallOutline className=" text-white" />
              </div>
              {companyDetails.companyPhone1}
            </a>
            <a
              href={`mailto:${companyDetails.companyEmail1}`}
              className="flex justify-start items-center gap-2 hover:text-red-500 cursor-pointer "
            >
              <div className=" bg-orange-400 p-1 rounded-full">
                <IoMailOutline className=" text-white" />
              </div>
              {companyDetails.companyEmail1}
            </a>
            <a
              href={companyDetails?.copmanyAddresslink}
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-start items-center gap-2 w-[300px] text-left hover:text-red-500"
            >
              <div className=" bg-orange-400 p-1 rounded-full">
                <GiDirectionSigns className=" text-white" />
              </div>
              <span>{content?.company_address}</span>
            </a>
          </div>
        </div>
      </div>
      <div className=" w-full flex justify-center items-center p-2 lg:px-20 pt-10 lg:pt-0">
        <p className=" font-bold">
          Developed By:&nbsp;{" "}
          <a
            className=" text-red-500 hover:text-red-600 font-bold"
            href="https://branding360.ae/"
            target="_blank"
          >
            Branding 360
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
