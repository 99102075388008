import { useEffect, useState } from "react";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import { FaCircleChevronUp } from "react-icons/fa6";

function BackToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 140) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <button
      onClick={handleScrollToTop}
      className={`fixed z-20 transition-all ease-in-out duration-500 right-12 w-[60px] h-[60px] rounded-full text-white font-bold ${
        isVisible ? "bottom-20" : "-bottom-20"
      }`}
    >
      <FaCircleChevronUp className="w-full h-full text-red-500" />
    </button>
  );
}

export default BackToTopButton;
