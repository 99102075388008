// import logo from './logo.svg';
// import { useState } from "react";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import internalLinks from "./internalLinks";

// import { useTranslation } from "react-i18next";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Footer from "./Components/Footer/Footer";
import ContactUs from "./Pages/ContactUs";
import Blogs from "./Pages/Blogs";
import SingleBlogPage from "./Pages/SingleBlogPage";
import ProductsPage from "./Pages/ProductsPage";
import SingleProductPage from "./Pages/SingleProductPage";
import Showrooms from "./Pages/Showrooms";
import SingleSowroomPage from "./Pages/SingleSowroomPage";
import BackToTopButton from "./Components/BackToTopButton/BackToTopButton";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    // Save original console methods
    const originalWarn = console.warn;
    const originalError = console.error;

    // Override console.warn to clear console after warning is logged
    console.warn = (...args) => {
      originalWarn(...args); // Log the original warning
      setTimeout(() => {
        console.clear(); // Clear the console after a delay
      }, 100); // Small delay to ensure the warning is logged before clearing
    };

    // Override console.error to clear console after error is logged
    console.error = (...args) => {
      originalError(...args); // Log the original error
      setTimeout(() => {
        console.clear(); // Clear the console after a delay
      }, 100); // Small delay to ensure the error is logged before clearing
    };

    return () => {
      // Restore the original methods when the component unmounts
      console.warn = originalWarn;
      console.error = originalError;
    };
  }, []);

  return (
    <div className="App bg-gray-100 relative ">
      <Router>
        <Navbar />
        <BackToTopButton />
        <Routes>
          <Route element={<Home />} path={internalLinks.home} />
          <Route element={<AboutUs />} path={internalLinks.aboutUs} />
          <Route element={<ContactUs />} path={internalLinks.contactUs} />
          <Route element={<Blogs />} path={internalLinks.blogs} />
          <Route
            element={<SingleBlogPage />}
            path={`${internalLinks.singleBlogPage}/:id`}
          />
          <Route element={<ProductsPage />} path={internalLinks?.products} />
          <Route
            element={<SingleProductPage />}
            path={`${internalLinks?.singleProductPage}/:id`}
          />
          <Route element={<Showrooms />} path={internalLinks?.showrooms} />
          <Route
            element={<SingleSowroomPage />}
            path={`${internalLinks?.showrooms}/:id`}
          />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
