import { useEffect, useRef, useState } from "react";
import axios from "axios";

import CompanyLogo1 from "../../Assets/Logos/CompanyLogo1.png";
import companyDetails from "../../CompanyDetails.js";
import { IoMailOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { GiDirectionSigns } from "react-icons/gi";
import { GiHamburgerMenu } from "react-icons/gi";
import { LuCross } from "react-icons/lu";
import { ImCross } from "react-icons/im";
import NavTrustedImg from "../../Assets/HomePage/NavTrustedImg.png";

import { Link, useLocation } from "react-router-dom";
import internalLinks from "../../internalLinks.js";
import { useTranslation } from "react-i18next";
import useContentFetcher from "../../CustomHooks/ContentFetcher/ContentFetcher.js";
import UniversalLoader from "../UniversalLoader/UniversalLoader.js";
import ErrorPage from "../../Pages/ErrorPage.js";
import { useGSAP } from "@gsap/react";
import { genericAnimationLeft } from "../../Animations/animations.js";
import useIntersectionObserver from "../../Animations/animationObserver.js";

function Navbar() {
  //For Animation
  const headerRef = useRef();
  const { contextSafe } = useGSAP();
  const floatRef = useIntersectionObserver("animate-float");

  //For Animation

  const location = useLocation();

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    scrollToTop();
  }, [location?.pathname]);

  const handleMenuOpen = () => {
    setMobileNavOpen(true);
  };

  const handleMenuClose = () => {
    setMobileNavOpen(false);
  };

  const navRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        handleMenuClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [navRef]);

  const [currentLocation, setCurrentLocation] = useState(null);

  useEffect(() => {
    setCurrentLocation(location?.pathname);
    handleMenuClose();
    // console.log("location.pathname", location.pathname);
  }, [location?.pathname]);

  const { i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  // const [content, setContent] = useState(null);

  // useEffect(() => {
  //   const fetchContent = async () => {
  //     const response = await axios.get(
  //       `../locales/${i18n.language}/common.json`
  //     );
  //     // console.log("response", response);
  //     setContent(response.data);
  //   };

  //   fetchContent();
  // }, [i18n, i18n.language]);

  const { content, contentLoading, contentError } = useContentFetcher("common");
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);

    setTimeout(() => {
      setAnimate(false);
    }, 500);
  }, [mobileNavOpen]);

  useEffect(() => {
    if (mobileNavOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [mobileNavOpen]);

  // ANIMATION

  const animateHeader = contextSafe(() => {
    genericAnimationLeft(headerRef);
  });

  useEffect(() => {
    animateHeader();
  }, [contentLoading]);

  // ANIMATION

  if (contentLoading) return <UniversalLoader />;

  if (contentError) return <ErrorPage error={contentError} />;

  const isActive = (path) => {
    const regex = new RegExp(`^${path}`);
    return regex.test(currentLocation);
  };

  return (
    <>
      <header className=" header hidden lg:flex lg:fixed z-30 w-full bg-white shadow-gray-400 shadow-md">
        <div
          ref={headerRef}
          className=" flex justify-start items-start gap-10 w-full h-[125px] text-sm"
        >
          <Link to={internalLinks?.home} className=" h-[100%]">
            <div className=" bg-red-700 px-12 py-3 h-[100%]">
              <img src={CompanyLogo1} className=" w-[100px]" alt="Logo" />
            </div>
          </Link>

          <div className=" flex flex-col justify-center items-start w-full pr-5 pt-5">
            <div className=" flex justify-between items-center w-full">
              <div className="flex gap-12">
                <a
                  href={`tel:${companyDetails.companyPhone1}`}
                  className="flex justify-start items-center gap-2 hover:text-red-500 cursor-pointer"
                >
                  <IoCallOutline />
                  {companyDetails.companyPhone1}
                </a>
                <a
                  href={`mailto:${companyDetails.companyEmail1}`}
                  className="flex justify-start items-center gap-2 hover:text-red-500 cursor-pointer "
                >
                  <IoMailOutline />
                  {companyDetails.companyEmail1}
                </a>
              </div>
              <a
                href={companyDetails?.copmanyAddresslink}
                target="_blank"
                rel="noopener noreferrer"
                className="flex justify-start items-center gap-2 w-[300px] text-left hover:text-red-500"
              >
                <GiDirectionSigns className="w-8 h-6" />
                <span className="text-xs">{content?.company_address}</span>
              </a>
            </div>
            <hr className=" w-full h-1 bg-gradient-to-r from-white to-red-600 rounded-md my-2" />
            <nav className=" flex justify-between items-center w-full font-medium">
              <ul className=" flex justify-start items-center gap-12">
                <ul className=" flex justify-start items-center gap-6 text-gray-800">
                  <Link
                    to={internalLinks.home}
                    className={`${
                      currentLocation === internalLinks.home
                        ? " bg-orange-400 px-5 py-1 text-white rounded-xl transition-all duration-500 "
                        : "px-5 py-1 hover:bg-orange-200 rounded-xl"
                    }`}
                  >
                    <li>{content?.home}</li>
                  </Link>
                  <Link
                    to={internalLinks.aboutUs}
                    className={`${
                      currentLocation === internalLinks.aboutUs
                        ? " bg-orange-400 px-5 py-1 text-white rounded-xl transition-all duration-500 "
                        : "px-5 py-1 hover:bg-orange-200 rounded-xl"
                    }`}
                  >
                    <li>{content?.about_us}</li>
                  </Link>
                  <Link
                    to={internalLinks?.products}
                    className={`${
                      isActive(internalLinks?.products)
                        ? " bg-orange-400 px-5 py-1 text-white rounded-xl transition-all duration-500 "
                        : "px-5 py-1 hover:bg-orange-200 rounded-xl"
                    }`}
                  >
                    <li>{content?.products}</li>
                  </Link>
                  <Link
                    to={`${internalLinks?.showrooms}/1`}
                    className={`${
                      isActive(internalLinks?.showrooms)
                        ? " bg-orange-400 px-5 py-1 text-white rounded-xl transition-all duration-500 "
                        : "px-5 py-1 hover:bg-orange-200 rounded-xl"
                    }`}
                  >
                    <li>{content?.branch_showroom}</li>
                  </Link>
                  <Link
                    to={internalLinks.blogs}
                    className={`${
                      isActive(internalLinks?.blogs)
                        ? " bg-orange-400 px-5 py-1 text-white rounded-xl transition-all duration-500 "
                        : "px-5 py-1 hover:bg-orange-200 rounded-xl"
                    }`}
                  >
                    <li>{content?.blogs}</li>
                  </Link>
                </ul>
              </ul>
              <ul className=" flex justify-start items-center gap-16">
                <Link to={internalLinks.contactUs}>
                  <li
                    className={` ${
                      currentLocation === internalLinks?.contactUs
                        ? " bg-red-500 text-white shadow-red-500 shadow-lg "
                        : "    text-red-500 hover:bg-red-500 hover:text-white"
                    } px-5 py-1 border-red-500 border-2 rounded-lg hover:shadow-red-500 hover:shadow-lg`}
                  >
                    {content?.contact_us}
                  </li>
                </Link>

                <li>
                  <select
                    onChange={handleLanguageChange}
                    defaultValue={i18n.language}
                    className=" outline-none cursor-pointer"
                  >
                    <option className=" cursor-pointer" value={"en"}>
                      English
                    </option>
                    <option className=" cursor-pointer" value={"hi"}>
                      हिंदी
                    </option>
                  </select>{" "}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <header className="lg:hidden flex fixed z-30 w-full bg-white shadow-gray-400 shadow-md">
        <div className=" flex justify-between items-center gap-10 w-full h-[80px] text-sm">
          <Link to={internalLinks?.home} className=" h-[100%]">
            <div className=" flex justify-center items-center bg-red-700 px-5 lg:px-12 py-3 h-[100%]">
              <img src={CompanyLogo1} className=" w-[50px]" alt="Logo" />
            </div>
          </Link>
          <div className=" bg-red-700 w-[80px] h-full flex justify-center items-center p-5 text-white font-bold">
            {mobileNavOpen ? (
              <ImCross
                className={`text-3xl text-white ${
                  animate ? "animate-spin" : " animate-none"
                }`}
                onClick={handleMenuClose}
              />
            ) : (
              <GiHamburgerMenu
                className={`text-4xl ${
                  animate ? "animate-spin" : " animate-none"
                }`}
                onClick={handleMenuOpen}
              />
            )}
          </div>

          <nav
            ref={navRef}
            className={`lg:hidden absolute transition-all ease-in-out duration-300 ${
              mobileNavOpen ? "right-0" : " -right-[500px]"
            } top-20 py-10 pb-20 rounded-lg shadow-gray-500 
            shadow-md text-lg bg-white flex flex-col justify-between 
            items-center w-[250px] font-medium gap-5`}
          >
            <ul className=" flex flex-col justify-start items-start gap-6 text-gray-800">
              <Link
                to={internalLinks.home}
                className={`${
                  currentLocation === internalLinks.home
                    ? " bg-orange-400 px-5 py-1 text-white rounded-xl transition-all duration-500 "
                    : "px-5 py-1 hover:bg-orange-200 rounded-xl"
                }`}
              >
                <li>{content?.home}</li>
              </Link>
              <Link
                to={internalLinks.aboutUs}
                className={`${
                  currentLocation === internalLinks.aboutUs
                    ? " bg-orange-400 px-5 py-1 text-white rounded-xl transition-all duration-500 "
                    : "px-5 py-1 hover:bg-orange-200 rounded-xl"
                }`}
              >
                <li>{content?.about_us}</li>
              </Link>
              <Link
                to={internalLinks?.products}
                className={`${
                  isActive(internalLinks?.products)
                    ? " bg-orange-400 px-5 py-1 text-white rounded-xl transition-all duration-500 "
                    : "px-5 py-1 hover:bg-orange-200 rounded-xl"
                }`}
              >
                <li>{content?.products}</li>
              </Link>
              <Link
                to={`${internalLinks?.showrooms}/1`}
                className={`${
                  isActive(internalLinks?.showrooms)
                    ? " bg-orange-400 px-5 py-1 text-white rounded-xl transition-all duration-500 "
                    : "px-5 py-1 hover:bg-orange-200 rounded-xl"
                }`}
              >
                <li>{content?.branch_showroom}</li>
              </Link>
              <Link
                to={internalLinks.blogs}
                className={`${
                  isActive(internalLinks?.blogs)
                    ? " bg-orange-400 px-5 py-1 text-white rounded-xl transition-all duration-500 "
                    : "px-5 py-1 hover:bg-orange-200 rounded-xl"
                }`}
              >
                <li>{content?.blogs}</li>
              </Link>
            </ul>

            <ul className=" flex flex-col justify-start items-center gap-5">
              <Link to={internalLinks.contactUs}>
                <li
                  className={` ${
                    currentLocation === internalLinks?.contactUs
                      ? " bg-red-500 text-white shadow-red-500 shadow-lg "
                      : "    text-red-500 hover:bg-red-500 hover:text-white"
                  } px-5 py-1 border-red-500 border-2 rounded-lg hover:shadow-red-500 hover:shadow-lg`}
                >
                  {content?.contact_us}
                </li>
              </Link>

              <li>
                <select
                  onChange={handleLanguageChange}
                  defaultValue={i18n.language}
                  className=" outline-none cursor-pointer"
                >
                  <option className=" cursor-pointer" value={"en"}>
                    English
                  </option>
                  <option className=" cursor-pointer" value={"hi"}>
                    हिंदी
                  </option>
                </select>{" "}
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Navbar;
