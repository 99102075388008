import * as React from "react";

import BecomeDealer from "../Components/BecomeDealer/BecomeDealer";
import ContactSection from "../Components/ContactSection/ContactSection";
import FaqSection from "../Components/FaqSection/FaqSection";
import HomeAboutBox from "../Components/HomeAboutBox/HomeAboutBox";
import HomeBannerCards from "../Components/HomeBannerCards/HomeBannerCards";
import HomeMetricsSection from "../Components/HomeMetricsSection/HomeMetricsSection";
import HomeMidContentBox from "../Components/HomeMidContentBox/HomeMidContentBox";
import HomePageBanner from "../Components/HomePageBanner/HomePageBanner";
import ProductsShowcase from "../Components/ProductsShowcase/ProductsShowcase";
import UniversalLoader from "../Components/UniversalLoader/UniversalLoader";
import WhyUsSection from "../Components/WhyUsSection/WhyUsSection";
import useContentFetcher from "../CustomHooks/ContentFetcher/ContentFetcher";
import ErrorPage from "./ErrorPage";

import HomeFaqImg from "../Assets/HomePage/HomeFaqImg.png";
import HomeFaqRightImg from "../Assets/HomePage/HomeGaqRightImg2.png";
import SimpleChatbot from "../Components/ChatBot/ChatBot";
import DealershipFormModal from "../Components/DealershipFormModal/DealershipFormModal";
import TestRideFormModal from "../Components/TestRideFormModal/TestRideFormModal";
import Catalogues from "../Components/Catalogues/Catalogues";

function Home() {
  const [dealershipFormOpen, setDealershipFormOpen] = React.useState(false);
  const handleDealershipFormOpen = () => setDealershipFormOpen(true);
  const handleDealershipFormClose = () => setDealershipFormOpen(false);

  const [testRideFormOpen, setTestRideFormOpen] = React.useState(false);
  const handleTestRideFormOpen = () => setTestRideFormOpen(true);
  const handleTestRideFormClose = () => setTestRideFormOpen(false);

  const { content, contentLoading, contentError } = useContentFetcher(
    "homePage"
  );

  // console.log("Homecontent:", content?.homeBannerContent);

  if (contentLoading) return <UniversalLoader />;

  if (contentError) return <ErrorPage error={contentError} />;

  return (
    <div className=" flex flex-col justify-center items-center bg-gray-100 gap-10">
      <HomePageBanner
        bannerContent={content?.homeBannerContent}
        handleTestRideFormOpen={handleTestRideFormOpen}
      />
      <HomeBannerCards cardsContent={content?.homeBannerCards} />
      <HomeAboutBox content={content?.homeAboutBox} />
      <ProductsShowcase />
      <HomeMidContentBox content={content?.homeMidContent} />
      {/* <HomeMetricsSection content={content?.homeMetrics} /> */}
      <BecomeDealer
        content={content?.becomeDealer}
        handleDealershipFormOpen={handleDealershipFormOpen}
      />
      <WhyUsSection content={content?.whyUs} />
      <ContactSection />
      <FaqSection
        content={content?.homeFaqSection}
        img={HomeFaqImg}
        imgRight={HomeFaqRightImg}
      />
      <div className=" w-[90%]">
        <Catalogues />
      </div>
      {/* <SimpleChatbot /> */}
      <DealershipFormModal
        handleClose={handleDealershipFormClose}
        open={dealershipFormOpen}
      />
      <TestRideFormModal
        handleClose={handleTestRideFormClose}
        open={testRideFormOpen}
      />
    </div>
  );
}

export default Home;
