import { FaHome } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import internalLinks from "../../internalLinks";

function PageHeader({ heading, subHeading, subPage }) {
  return (
    <div className=" w-full flex flex-col lg:flex-row gap-y-5 text-left lg:justify-between lg:items-center">
      <div className=" flex flex-col justify-start items-start">
        <span className=" border-l-4 pl-2 font-semibold border-orange-400">
          {heading}
        </span>
        <h2 className=" text-2xl lg:text-5xl font-semibold">{subHeading}</h2>
      </div>
      <div className=" flex justify-start items-center gap-2">
        <div className="text-sm font-semibold hover:text-red-500 flex justify-start items-center gap-2 ">
          <FaHome />
          <Link to={internalLinks.home}>Home</Link>
        </div>

        <FaAngleRight />
        {internalLinks[heading.toLowerCase()] ? (
          <Link
            className="text-sm font-semibold hover:text-red-500 flex justify-start items-center gap-2 "
            to={internalLinks[heading.toLowerCase()]}
          >
            {heading}
          </Link>
        ) : (
          heading
        )}
        {subPage && (
          <div className=" flex gap-2 justify-center items-center">
            <FaAngleRight />
            {subPage}
          </div>
        )}
      </div>
    </div>
  );
}

export default PageHeader;
