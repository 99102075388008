import MainContentContainer from "../MainContentContainer/MainContentContainer";

import HomeMidImg1 from "../../Assets/HomePage/HomeMidImg1.png";
import HomeMidImg2 from "../../Assets/HomePage/HomeMidImg2.png";
import HomeMidImg3 from "../../Assets/HomePage/HomeMidImg3.png";
import ProductImg1 from "../../Assets/Products/ProductImg1.png";
import ProductImg2 from "../../Assets/Products/ProductImg4.png";
import ProductImg3 from "../../Assets/Products/ProductImg3.png";

import ClassicEcoHomeImg from "../../Assets/HomePage/ClassicEcoHomeImg.png";
import PrimeHomeImg from "../../Assets/HomePage/PrimeHomeImg.png";
import PrimusHomeImg from "../../Assets/HomePage/PrimusHomeImg.png";

// import HomeMidBottomImg1 from "../../Assets/HomePage/HomeMidBottomImg1.png";
// import HomeMidBottomImg2 from "../../Assets/HomePage/HomeMidBottomImg2.png";
// import HomeMidBottomImg3 from "../../Assets/HomePage/HomeMidBottomImg3.png";
import useContentFetcher from "../../CustomHooks/ContentFetcher/ContentFetcher";
import { useGSAP } from "@gsap/react";
import {
  animationGenClassLeftScrollTriggered,
  animationGenClassRightScrollTriggered,
} from "../../Animations/animations";

function HomeMidContentBox() {
  const { content, contentLoading, contentError } = useContentFetcher(
    "products"
  );

  useGSAP(
    () => {
      animationGenClassLeftScrollTriggered();
      animationGenClassRightScrollTriggered();
    },
    { scope: ".mid-content-box" }
  );

  const topThreeProducts = content?.products?.slice(0, 3);

  const productImages = [ClassicEcoHomeImg, PrimusHomeImg, PrimeHomeImg];

  // const images = [HomeMidImg1, HomeMidImg2, HomeMidImg3];
  return (
    <div className=" mid-content-box w-[90%] px-10 py-10 bg-white rounded-lg flex flex-col gap-10">
      {topThreeProducts?.map((data, index) => (
        <MainContentContainer
          className="animate-left-class"
          img={productImages[index]}
          flip={index % 2 === 1}
          content={data}
          key={index}
        />
      ))}
      {/* <div className=" flex justify-center items-center gap-5 w-[100%]">
        <img
          className=" rounded-lg w-[30%]"
          src={HomeMidBottomImg1}
          alt="Home Mid"
        />
        <img
          className=" rounded-lg w-[30%]"
          src={HomeMidBottomImg2}
          alt="Home Mid"
        />
        <img
          className=" rounded-lg w-[30%]"
          src={HomeMidBottomImg3}
          alt="Home Mid"
        />
      </div> */}
    </div>
  );
}

export default HomeMidContentBox;
