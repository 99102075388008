import CompanyLogo from "../../Assets/Logos/logo192.png";

function DealerCard({
  firmName,
  city,
  handleTestRideFormOpen,
  bookTestRideText,
}) {
  return (
    <div
      className=" w-[250px] lg:w-[300px] h-[200px] bg-white border-2 border-red-600 
    flex flex-col justify-center items-start gap-5 text-left p-5 rounded-lg"
    >
      <h4 className=" lg:text-xl text-gray-800 font-bold">{firmName}</h4>
      <div className=" w-full flex justify-between items-center">
        <h5 className=" text-base text-gray-600 font-bold">{city}</h5>
        <img
          className=" w-[40px] h-[40px] opacity-50"
          src={CompanyLogo}
          alt="Company Logo"
        />
      </div>
      <button
        className=" bg-transparent text-gray-500 font-semibold border-[2px] 
          border-gray-300 hover:border-transparent hover:bg-red-500 
          hover:text-white shadow-md hover:shadow-red-400 
          px-3 py-1 rounded-lg w-full "
        onClick={handleTestRideFormOpen}
      >
        {bookTestRideText}
      </button>
    </div>
  );
}

export default DealerCard;
