import ClassicEcoImg1 from "../../Assets/Products/ClassicEcoImg1.png";
import SuvidhaDeluxePlusImg1 from "../../Assets/Products/SuvidhaDeluxePlusImg1.png";
import SuvidhaPrimusImg1 from "../../Assets/Products/SuvidhaPrimusImg1.png";
import SuvidhaDeluxePlusImg2 from "../../Assets/Products//SuvidhaDeluxePlusImg2.png";

function Catalogues() {
  return (
    <div className=" w-[90%] lg:w-[100%] flex flex-col justify-center items-start gap-10">
      <div className=" flex flex-col justify-start items-start">
        <span className=" border-l-4 pl-2 font-semibold border-orange-400">
          Catalogues
        </span>
        <h2 className=" text-5xl font-semibold">Catalogues</h2>
      </div>
      <div className=" w-full flex justify-center lg:justify-between flex-wrap gap-5 gap-y-5 items-center cursor-pointer ">
        <a href="https://wa.me/p/7721687457879379/918090053944" target="_blank">
          <div className=" bg-red-500 w-[250px] h-[380px] rounded-lg relative group">
            <img
              className=" w-full h-full rounded-lg opacity-100 
            transition-opacity duration-500 group-hover:opacity-20"
              src={ClassicEcoImg1}
              alt="Catalogue"
            />
            <div
              className=" w-full h-full flex justify-center items-center 
            absolute top-0 left-0 rounded-lg 
          opacity-0 transition-opacity duration-500 group-hover:opacity-100"
            >
              <a className=" text-white text-2xl font-bold text-center">
                Click To Open Catalogue
              </a>
            </div>
          </div>
        </a>
        <a href="https://wa.me/p/7586753491394150/918090053944" target="_blank">
          <div className=" bg-red-500 w-[250px] h-[380px] rounded-lg relative group drop-shadow-lg">
            <img
              className=" w-full h-full rounded-lg opacity-100 
            transition-opacity duration-500 group-hover:opacity-20 object-cover"
              src={SuvidhaDeluxePlusImg1}
              alt="Catalogue"
            />
            <div
              className=" w-full h-full flex justify-center items-center 
            absolute top-0 left-0 rounded-lg 
          opacity-0 transition-opacity duration-500 group-hover:opacity-100"
            >
              <a className=" text-white text-2xl font-bold text-center">
                Click To Open Catalogue
              </a>
            </div>
          </div>
        </a>

        <a href="https://wa.me/p/8085601561463748/918090053944" target="_blank">
          <div className=" bg-red-500 w-[250px] h-[380px] rounded-lg relative group shadow-lg">
            <img
              className=" w-full h-full rounded-lg opacity-100 
            transition-opacity duration-500 group-hover:opacity-20"
              src={SuvidhaPrimusImg1}
              alt="Catalogue"
            />
            <div
              className=" w-full h-full flex justify-center items-center 
            absolute top-0 left-0 rounded-lg 
          opacity-0 transition-opacity duration-500 group-hover:opacity-100"
            >
              <a className=" text-white text-2xl font-bold text-center">
                Click To Open Catalogue
              </a>
            </div>
          </div>
        </a>
        <a href="https://wa.me/p/7432644740138174/918090053944" target="_blank">
          <div className=" bg-red-500 w-[250px] h-[380px] rounded-lg relative group shadow-lg">
            <img
              className=" w-full h-full rounded-lg object-cover opacity-100 
            transition-opacity duration-500 group-hover:opacity-20"
              src={SuvidhaDeluxePlusImg2}
              alt="Catalogue"
            />
            <div
              className=" w-full h-full flex justify-center items-center 
            absolute top-0 left-0 rounded-lg 
          opacity-0 transition-opacity duration-500 group-hover:opacity-100"
            >
              <a className=" text-white text-2xl font-bold text-center">
                Click To Open Catalogue
              </a>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Catalogues;
